import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/solid'
import { Fragment, useEffect, useState } from 'react'
import { classNames } from '../utils/helpers'

export function Drawer({ right = false, open, onOpenStateChange, initialState, children }) {
  const [sidebarOpen, setSidebarOpen] = useState(initialState)

  useEffect(() => {
    setSidebarOpen(open)
  }, [open])

  function handleClose(val) {
    onOpenStateChange(val)
  }
  return (
    <Transition.Root show={sidebarOpen} as={Fragment}>
      <Dialog
        as="div"
        className={classNames('fixed inset-0 z-40 flex md:hidden', right ? 'right-0' : '')}
        onClose={handleClose}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>
        {right && (
          <div className="w-14 flex-shrink-0">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        )}
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom={right ? ' translate-x-full' : '-translate-x-full'}
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo={right ? 'translate-x-full' : '-translate-x-full'}
        >
          <div
            className={classNames(
              'relative flex max-w-xs flex-col bg-white pb-4 pt-5',
              right ? 'ml-auto' : '',
            )}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className={classNames(
                  'absolute top-0 pt-2',
                  right ? 'left-0 -ml-12' : 'right-0 -mr-12',
                )}
              >
                <button
                  type="button"
                  className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={() => handleClose(false)}
                >
                  <span className="sr-only">Close sidebar</span>
                  <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </Transition.Child>
            <div className="scrollbar mt-5 h-0 flex-1 overflow-y-auto">
              <nav className="flex flex-col items-center space-y-1 px-2">{children}</nav>
            </div>
          </div>
        </Transition.Child>
        {!right && (
          <div className="w-14 flex-shrink-0">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        )}
      </Dialog>
    </Transition.Root>
  )
}
