import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { IContinuity, IProduct } from 'types/modelTypes'

import { formatter } from 'utils/helpers'

import AddProductModal from 'components/modals/AddProductModal'

import { setFilters } from 'data/globals'

interface IBuilderCoreNotesProps {
  slide: IContinuity
  deckTitle: string
}
export const BuilderCoreNotes = ({ slide, deckTitle }: IBuilderCoreNotesProps) => {
  const [showAddProductModal, setShowAddProductModal] = useState(false)

  function createFilter(prop: string, attr: string) {
    const newFilter = {
      key: uuidv4(),
      complete: true,
      prop: {
        label: `${prop[0].toUpperCase()}${prop.slice(1)}`,
        value: prop,
        type: 'string',
      },
      attr: [
        {
          name: attr,
          label: attr,
          value: attr,
        },
      ],
      operator: {
        label: 'Equals',
        value: 'or',
        type: ['string', 'bool', 'int'],
        filterType: 'and',
      },
    }
    setFilters([newFilter])
  }

  function onBrandClick(product: IProduct) {
    if (product.brand) {
      createFilter('brand', product.brand)
    }
    setShowAddProductModal(true)
  }

  function onVendorClick(product: IProduct) {
    if (product.vendor) {
      createFilter('vendor', product.vendor)
    }
    setShowAddProductModal(true)
  }

  function onProductIdClick(product: IProduct) {
    createFilter('productId', product.productId)
    setShowAddProductModal(true)
  }
  return (
    <>
      <div className="pb-20 pt-28">
        <p className="text-base font-semibold">Product Details</p>
        {slide?.products?.map((p) => {
          if (!p.name) return null
          return (
            <div key={p.id} className="pb-4">
              <p className="text-xs">
                {p.name}: {formatter.format(p.cost)}
              </p>
              <button
                onClick={() => onBrandClick(p)}
                className="cursor-pointer text-xs text-purple-500"
              >
                {p.brand} /&nbsp;
              </button>
              <button
                onClick={() => onVendorClick(p)}
                className="cursor-pointer text-xs text-purple-500"
              >
                {p.vendor} /&nbsp;
              </button>
              <button
                onClick={() => onProductIdClick(p)}
                className="cursor-pointer text-xs text-purple-500"
              >
                {p.productId}
              </button>
            </div>
          )
        })}
      </div>
      <AddProductModal
        show={showAddProductModal}
        title={`Add products${deckTitle ? ` to ${deckTitle}` : ''}`}
        fromNotes={true}
        slot={0}
        onClose={() => {
          setShowAddProductModal(false)
        }}
      />
    </>
  )
}
