export default function SaveCard({ category, imageSrc, onClick }) {
  function handleClick() {
    onClick?.(category)
  }
  return (
    <button onClick={handleClick} className="group block">
      <div
        aria-hidden="true"
        className="aspect-h-2 aspect-w-3 overflow-hidden lg:aspect-h-5 lg:aspect-w-5 group-hover:opacity-75"
      >
        <img src={imageSrc} className="h-full w-full object-cover object-center" />
      </div>
      {/* <h3 className="mt-4 text-base font-semibold text-gray-900">{collection.name}</h3> */}
      {/* <p className="mt-2 text-sm text-gray-500">{collection.description}</p> */}
    </button>
  )
}
