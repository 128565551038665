import axios from 'axios'
import { format } from 'date-fns'
import * as htmlToImage from 'html-to-image'
import jwt_decode from 'jwt-decode'
import Resizer from 'react-image-file-resizer'

import { updateCurrentSlide } from '../data/decks'
import constants from './constants'

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const responseBody = (response) => response?.data

export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
})

export const dateStringFormat = (date) => {
  // returns a string in the format of 'Jan 31st, 3417'
  return format(new Date(date), 'MMM do, yyyy')
}

export const dollarsToCents = (amount) => {
  if (typeof amount !== 'string' && typeof amount !== 'number') {
    throw new Error('Amount passed must be of type String or Number.')
  }

  return Math.round(
    100 * parseFloat(typeof amount === 'string' ? amount.replace(/[$,]/g, '') : amount),
  )
}

export const toDataURL = (src, outputFormat) => {
  return new Promise((resolve, reject) => {
    var img = new Image()
    img.crossOrigin = 'Anonymous'
    img.onload = function () {
      var canvas = document.createElement('CANVAS')
      var ctx = canvas.getContext('2d')
      var dataURL
      canvas.height = this.naturalHeight
      canvas.width = this.naturalWidth
      ctx.drawImage(this, 0, 0)
      dataURL = canvas.toDataURL(outputFormat)
      resolve(dataURL)
    }
    img.onerror = function (err) {
      reject(err)
    }
    img.src = src
    if (img.complete || img.complete === undefined) {
      img.src = src
    }
  })
}

export const validateToken = (token) => {
  if (!token) {
    return false
  }
  try {
    const decodedJwt = jwt_decode(token)
    return decodedJwt.exp >= Date.now() / 1000
  } catch (e) {
    return false
  }
}

export const resizeComputedImage = (url, height, width) => {
  if (!url) return
  if (!url.startsWith('https')) {
    url = url.replace('http', 'https')
  }
  let splitUrl = url.split('/')
  splitUrl.splice(splitUrl.length - 1, 0, `w_${width},h_${height},c_pad`)
  return splitUrl.join('/')
}

// @DEV - Return total 'Cost' of a continuity per week including any price modifiers
export const totalContinuityPrice = (continuity, type, unmodified = false) => {
  if (!Array.isArray(continuity.products)) {
    return
  }
  const weeks =
    type === constants.CONTINUITY_TYPES.QUAD ? 4 : type === constants.CONTINUITY_TYPES.QUINT ? 5 : 1
  let totalCost =
    continuity.products.reduce((prev, curr) => {
      return prev + Number(curr?.cost ?? 0)
    }, 0) / weeks
  const modifier = unmodified ? 1 : continuity.priceModifier
  const modifiedCostPerWeek = totalCost / modifier

  return formatter.format(roundUp(modifiedCostPerWeek))
}

export const generatePowerpointNotes = (cont) => {
  let notes = `${format(new Date(), 'MM-dd-yyyy')}
Ref#: ${cont.id || 'none'}
Total cost: ${totalContinuityPrice(cont, constants.CONTINUITY_TYPES.SINGLE, true)}
-------------------------------------------------------------------`
  cont.products.forEach((p, idx) => {
    notes += `
${p.name}
Vendor: ${p.vendor}
Product Item #: ${p.manufacturerId}
Product Cost: ${formatter.format(p.cost)}
Quantity: ${p.inventory || 'Unknown'}
Lead Time: ${p.leadTime || 'Unknown'}
-------------------------------------------------------------------`
    if (p.tier1) {
      notes += `Tier 1: ${p.tier1 || 0} at ${p.tier1Qty}`
    }
    if (p.tier2) {
      notes += `Tier 2: ${p.tier2 || 0} at ${p.tier2Qty}`
    }
    if (p.tier3) {
      notes += `Tier 3: ${p.tier3 || 0} at ${p.tier3Qty}`
    }
    if (p.tier4) {
      notes += `Tier 4: ${p.tier4 || 0} at ${p.tier4Qty}`
    }
  })
  return notes
}

/* @PARAMS: 
  num: number
*/
export const roundUp = (num) => {
  return Math.ceil(num * 10) / 10
}

/* @PARAMS: 
  cont: continuity object
  p: a continuity product
*/
export const computePrimaryImage = (cont, p) => {
  const altImageIdx = cont.altImages[p?.id]
  return altImageIdx >= 0
    ? p?.imageAssets[altImageIdx]?.replace(/https?/, 'https')
    : p?.transparentImage
    ? p?.transparentImage.replace(/https?/, 'https')
    : p?.computedPrimaryImage?.replace(/https?/, 'https')
}

/* @PARAMS: 
  user: user state object
*/
export const generateInitials = (user) => {
  if (!user || !user.name) {
    return 'NU'
  }
  const nameSplit = user?.name.split(' ')
  return nameSplit
    .slice(0, 2)
    .map((word) => word.charAt(0))
    .join('')
}

export const captureSlide = () => {
  // todo pass in the slide index and do not rely on currentSlide
  setTimeout(() => {
    const node = document.querySelector('#capture')
    if (!node) return
    htmlToImage
      // .toPng(node, { quality: 0.6, cacheBust: true })
      .toBlob(node, {
        quality: 0.6,
        cacheBust: true,
        filter: function (node) {
          // do not capture elements with the 'ignore-capture' class and thier children
          return !node.classList?.contains?.('ignore-capture')
        },
      })
      .then(function (dataUrl) {
        updateCurrentSlide({
          thumbnail: URL.createObjectURL(dataUrl),
          // thumbnail: dataUrl,
        })
      })
      .catch((error) => console.error('htmlToImage error', error))
  }, 600)
}

export function resizeArray(arr, newLength, defaultValue) {
  // return [...arr, ...Array(Math.max(newSize - arr.length, 0)).fill(defaultValue)]
  const oldLength = arr.length
  arr.length = newLength
  if (newLength > oldLength && typeof defaultValue !== 'undefined') {
    for (let i = oldLength; i < newLength; i++) {
      // Note: this will create many references to the same object so we use Object.create
      if (typeof defaultValue === 'object') {
        arr[i] = Object.create(defaultValue)
      } else {
        arr[i] = defaultValue
      }
    }
  }
  return arr
}

export const resizeFile = (file, opts) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      opts.maxWidth || 300,
      opts.maxHeight || 300,
      opts.format || 'PNG',
      opts.quality || 100,
      opts.rotation || 0,
      (uri) => {
        resolve(uri)
      },
      opts.outputFile || 'file',
    )
  })

export function formatOrgTierPricing(cost, markup, isAdmin = false) {
  if (isAdmin) {
    return formatter.format(roundUp(cost))
  }
  if (cost < constants.ORG_TIERS.tier1) {
    return formatter.format(roundUp(cost / (1 - markup.tier1)))
    // return formatter.format(roundUp(Number(cost) + cost * markup.tier1))
  }
  if (cost < constants.ORG_TIERS.tier2) {
    return formatter.format(roundUp(cost / (1 - markup.tier2)))
    // return formatter.format(roundUp(Number(cost) + cost * markup.tier2))
  }
  if (cost < constants.ORG_TIERS.tier3) {
    return formatter.format(roundUp(cost / (1 - markup.tier3)))
    // return formatter.format(roundUp(Number(cost) + cost * markup.tier3))
  }
  if (cost < constants.ORG_TIERS.tier4) {
    return formatter.format(roundUp(cost / (1 - markup.tier4)))
    // return formatter.format(roundUp(Number(cost) + cost * markup.tier4))
  }
  return formatter.format(roundUp(cost / (1 - markup.tier5)))
  // return formatter.format(roundUp(Number(cost) + cost * markup.tier5))
}

export const thunkErrorHandler = (error, thunkAPI) => {
  if (axios.isAxiosError(error)) {
    return thunkAPI.rejectWithValue(error.message)
  } else {
    return thunkAPI.rejectWithValue(error)
  }
}
