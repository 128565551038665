import { ArrowNarrowLeftIcon } from '@heroicons/react/solid'

interface IBackButtonProps {
  text?: String
  onClick: () => void
}
export function BackButton({ text = 'Go Back', onClick }: IBackButtonProps) {
  return (
    <button
      role="button"
      onClick={onClick}
      className="flex items-center text-purple-500 outline-none hover:text-purple-300 hover:opacity-70 focus:outline-none"
    >
      <ArrowNarrowLeftIcon className="mr-2 inline-block h-5 w-5" aria-hidden="true" />
      {text}
    </button>
  )
}
