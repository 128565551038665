interface IOutlineButtonProps {
  icon: JSX.Element
  text: String
  onClick: () => void
}
export function OutlineButton({ icon, text, onClick }: IOutlineButtonProps) {
  return (
    <button
      onClick={onClick}
      type="button"
      className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0"
    >
      {icon}
      <span>{text}</span>
    </button>
  )
}
