import { SquareButton } from 'components/buttons/SquareButton'
import useCSVExport from 'hooks/useCSVExport'

export default function ExportCsvButton({ className }) {
  const { exportCSV } = useCSVExport()

  function onExport() {
    exportCSV()
  }

  return <SquareButton text="EXPORT TO CSV" onClick={onExport} size="lg" className={className} />
}
