import { forwardRef, useState } from 'react'
import { classNames } from '../../utils/helpers'

interface ITextInputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'prefix'> {
  initialValue?: string
  onChange: (value: string) => void
  type?: string
  srOnly?: boolean
  label?: string
  containerClass?: string
  inputClass?: string
  prefix?: boolean
  suffix?: string | null
}
export const TextInput = forwardRef<HTMLInputElement, ITextInputProps>(
  (
    {
      initialValue = '',
      onChange,
      type = 'text',
      srOnly = true,
      label,
      containerClass,
      inputClass,
      prefix = false,
      suffix = null,
      ...rest
    },
    ref,
  ) => {
    const [value, setValue] = useState(initialValue)

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
      const v = e.target.value
      setValue(v)
      onChange(v)
    }

    return (
      <div className={classNames('w-full', containerClass)}>
        {label ? (
          <label
            htmlFor={label}
            className={classNames('text-sm font-semibold', `${srOnly ? 'sr-only' : ''}`)}
          >
            {label}
          </label>
        ) : null}
        <div className="relative">
          {prefix && (
            <div className="pointer-events-none absolute inset-y-0 left-1 flex h-full items-center">
              <span className="text-gray-500 sm:text-sm">$</span>
            </div>
          )}
          <input
            ref={ref}
            type={type}
            value={value}
            onChange={handleChange}
            className={classNames(
              'block w-full border-gray-700 shadow-sm outline-none ring-0 focus:border-purple-500 focus:outline-none focus:ring-0 sm:text-sm',
              inputClass,
            )}
            {...rest}
          />
          {suffix && (
            <div className="pointer-events-none absolute inset-y-0 right-8 flex h-full items-center">
              <span className="text-gray-500 sm:text-sm">{suffix}</span>
            </div>
          )}
        </div>
      </div>
    )
  },
)

TextInput.displayName = 'TextInput'
