import { Tab } from '@headlessui/react'

import MasterSlidesView from '../MasterSlidesView'
import { MyListsTab } from '../MyListsTab'
import ProductsView from '../ProductsView'
import { GenericModal } from './GenericModal'

import { classNames } from '../../utils/helpers'

interface IAddProductModalProps {
  title?: string
  show: boolean
  onClose: () => void
  slot: number
  fromNotes?: boolean
  defaultIndex?: number
}
export default function AddProductModal({
  title,
  show,
  onClose,
  slot,
  fromNotes,
  defaultIndex = 0,
}: IAddProductModalProps) {
  function handleAddComplete() {
    onClose()
  }

  return (
    <GenericModal
      title={title}
      show={show}
      onClose={handleAddComplete}
      showButtons={false}
      fullWidth={true}
    >
      <Tab.Group defaultIndex={defaultIndex}>
        <Tab.List className="flex space-x-1 rounded-xl p-1">
          {['My Lists', 'Catalog', 'Master Slides'].map((category) => (
            <Tab
              key={category}
              className={({ selected }) =>
                classNames(
                  selected
                    ? 'border-purple-500 text-gray-900'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'whitespace-nowrap border-b-2 px-1 py-2 text-sm font-medium outline-none ring-0 focus:outline-none',
                )
              }
            >
              {category}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="h-full">
          <Tab.Panel className={classNames('h-full bg-white', 'focus:outline-none focus:ring-0')}>
            <div style={{ height: '66vh' }} className="relative overflow-y-scroll">
              <MyListsTab slot={slot} onAddComplete={handleAddComplete} />
            </div>
          </Tab.Panel>
          <Tab.Panel className={classNames('bg-white', 'h-full focus:outline-none focus:ring-0')}>
            <div style={{ height: '66vh' }} className="relative overflow-y-scroll">
              <ProductsView
                slot={slot}
                fromNotes={fromNotes}
                onAddComplete={handleAddComplete}
                useCache={true}
                builder={true}
              />
            </div>
          </Tab.Panel>
          <Tab.Panel className={classNames('bg-white', 'h-full focus:outline-none focus:ring-0')}>
            <div style={{ height: '66vh' }} className="relative overflow-y-scroll">
              <MasterSlidesView builder={true} onAddComplete={handleAddComplete} />
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </GenericModal>
  )
}
