import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  UniqueIdentifier,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { useState } from 'react'

import { ProductSelector } from 'components/continuityBuilder/ProductSelector'

import { updateCurrentSlide } from 'data/decks'

import { ReactComponent as GPSLogo } from 'assets/svgs/gps_logo.svg'

import { IContinuity, IProduct } from 'types/modelTypes'

import { useComputedPrice } from 'hooks/useComputedPrice'

interface ISortableItemProps {
  id: string | number
  value: IProduct
  idx: number
  onAddProduct: (idx: number) => void
  slide: IContinuity
}
function SortableItem({ id, value, idx, onAddProduct, slide }: ISortableItemProps) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: String(id),
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <ProductSelector
      ref={setNodeRef}
      attributes={attributes}
      listeners={listeners}
      style={style}
      key={idx}
      product={value}
      continuity={slide}
      idx={idx}
      onAddProduct={onAddProduct}
      containerStyle={{ width: '25%', padding: `12px 12px 0 12px` }}
      horizontal={true}
    />
  )
}

interface IFourItemContinuityHorizontalProps {
  slide: IContinuity
  onAddProduct: (idx: number) => void
}
export const FourItemContinuityHorizontal = ({
  slide,
  onAddProduct,
}: IFourItemContinuityHorizontalProps) => {
  const { computedPrice } = useComputedPrice(slide)
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(PointerSensor),
  )

  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null)

  function handleDragStart(event: DragStartEvent) {
    const { active } = event
    setActiveId(active.id)
  }

  async function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event
    if (active.id !== over?.id) {
      const items = [...slide.products]
      const oldIndex = items.findIndex((i) => String(i.id) === active.id)
      const newIndex = items.findIndex((i) => String(i.id) == over?.id)
      const newOrderedProducts = arrayMove(items, oldIndex, newIndex)
      updateCurrentSlide({
        products: newOrderedProducts,
      })
    }

    setActiveId(null)
  }

  function handleDragCancel() {
    setActiveId(null)
  }

  return (
    <div className="relative flex h-full w-full flex-col bg-white">
      {/* <ProductSelectorBackground slide={slide}> */}
      <div className="relative flex h-[60%] min-h-[60%] w-full flex-row items-center justify-around px-3 pt-3">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragCancel={handleDragCancel}
        >
          <SortableContext
            items={slide.products.map((p) => ({ id: String(p.id) }))}
            strategy={horizontalListSortingStrategy}
          >
            {slide.products.slice(0, 4).map((p, idx) => {
              return (
                <SortableItem
                  key={p.id}
                  idx={idx}
                  id={p.id}
                  value={p}
                  slide={slide}
                  onAddProduct={onAddProduct}
                />
              )
            })}
          </SortableContext>
          <DragOverlay>
            {activeId ? (
              <ProductSelector
                ref={null}
                listeners={{}}
                style={{}}
                product={slide.products.find((p) => String(p.id) === activeId)!}
                continuity={slide}
                idx={slide.products.findIndex((p) => String(p.id) === activeId)}
                onAddProduct={() => {}}
                size={
                  slide.layout === 'bordered' || slide.layout === 'background' ? '180px' : '220px'
                }
              />
            ) : null}
          </DragOverlay>
        </DndContext>
      </div>
      {/* </ProductSelectorBackground> */}
      {slide.products.some((p) => Object.keys(p).length > 2) ? (
        <div className="flex h-full w-full flex-row overflow-hidden px-6 pb-6 pt-8">
          <div className="flex w-1/2 flex-col justify-between pr-4">
            <h4 className="mb-2 line-clamp-3 w-full overflow-ellipsis text-xl font-semibold uppercase leading-5 xl:text-3xl xl:leading-7">
              {slide.title || 'TITLE'}
            </h4>
            <div className="flex flex-col">
              <span className="text-xl font-semibold leading-5">{computedPrice}</span>
              <span className="text-xs text-gray-500">+ SHIPPING PER WEEK</span>
            </div>
          </div>
          <GPSLogo className="absolute bottom-2 right-5 h-14 w-14 text-purple-500" />
        </div>
      ) : (
        // TODO fix these ghost layouts to match the horizontal layout
        <div className="relative flex h-full flex-row p-6">
          <div className="flex w-full flex-col items-start justify-between">
            <div className="mb-2 h-12 w-3/4 rounded-full bg-gray-200" />
            <div className="mb-2 h-9 w-2/5 rounded-full bg-gray-200" />
          </div>
          <div className="flex w-full flex-col items-start justify-between">
            <div className="mb-2 h-12 w-full rounded-full bg-gray-200" />
            <div className="h-14 w-1/3 rounded-xl bg-gray-200" />
          </div>
          <GPSLogo className="absolute bottom-2 right-5 h-14 w-14 text-purple-500" />
        </div>
      )}
    </div>
  )
}
