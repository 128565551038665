import { classNames } from '../../utils/helpers'

interface IButtonSquareProps {
  onClick: () => void
  text: String
  className?: String
  color?: String
  textColor?: String
  disabled?: boolean
  outline?: boolean
  small?: boolean
  selected?: boolean
}
export const ButtonSquare = ({
  onClick,
  text,
  className = '',
  color,
  textColor,
  disabled = false,
  outline = false,
  small = false,
  selected = false,
}: IButtonSquareProps) => {
  const _bgColor = color || 'bg-purple'
  const _textColor = textColor || 'text-white'
  return (
    <button
      role="button"
      onClick={onClick}
      type="button"
      disabled={disabled}
      className={classNames(
        'safe whitespace-nowrap rounded text-center shadow-sm',
        small ? 'px-2 py-1 text-xs' : 'h-8 px-4 py-1 text-base',
        outline
          ? `border ${_textColor} outline-none focus:outline-none`
          : `border border-transparent ${_textColor} ${_bgColor}-500 hover:${_bgColor}-700 transition-colors outline-none duration-200 focus:outline-none`,
        selected ? `border-purple-500` : '',
        className,
      )}
    >
      {text}
    </button>
  )
}
