import { classNames } from '../../utils/helpers'

export default function AdvancedFilterSwitchBox({ containerClass, onChange, selected }) {
  function handleAnd() {
    onChange('and')
  }

  function handleOr() {
    onChange('or')
  }

  return (
    <span
      className={classNames(
        'isolate inline-flex rounded-md border border-gray-300 shadow-sm',
        containerClass,
      )}
      style={{ height: '38px' }}
    >
      <button
        type="button"
        onClick={handleAnd}
        className={classNames(
          selected === 'and'
            ? 'bg-green-600 text-white hover:bg-green-700'
            : 'bg-white text-gray-500 hover:bg-gray-50',
          'relative inline-flex w-11 items-center rounded-l-md py-2 focus:z-10 focus:outline-none focus:ring-0',
        )}
      >
        <span className="sr-only">And</span>
        <span className="w-full">And</span>
      </button>
      <button
        type="button"
        onClick={handleOr}
        className={classNames(
          selected === 'or'
            ? 'bg-green-600 text-white hover:bg-green-700'
            : 'bg-white text-gray-500 hover:bg-gray-50',
          'relative inline-flex w-11 items-center rounded-r-md py-2 focus:z-10 focus:outline-none focus:ring-0',
        )}
      >
        <span className="sr-only">Or</span>
        <span className="w-full">Or</span>
      </button>
    </span>
  )
}
