import { useEffect, useRef, useState } from 'react'
import Masonry from 'react-masonry-css'
import { useQuery } from 'react-query'

import { LoadingSpinner } from 'components/LoadingSpinner'
import { SearchInput } from 'components/inputs/SearchInput'
import { useErrorAlert } from 'hooks/useErrorAlert'
import { api } from 'services/api'
import { IUnsplashImage } from 'types/modelTypes'

import './masonry.css'

interface IBackgroundImageModalUnsplashTabProps {
  onSelect: ({ url }: { url: string }) => void
}
export default function BackgroundImageModalUnsplashTab({
  onSelect,
}: IBackgroundImageModalUnsplashTabProps) {
  const { errorAlert } = useErrorAlert()

  const [searchTerm, setSearchTerm] = useState('')

  const formRef = useRef<HTMLButtonElement>(null)

  const fetchGallery = async () => {
    try {
      const { data } = await api.Unsplash.search({ query: searchTerm, page: 1, per_page: 40 })
      return data.results
    } catch (error) {
      errorAlert(error)
    }
  }

  const { isLoading, data, error } = useQuery(['unsplash-images', searchTerm], fetchGallery, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (error) {
      errorAlert(error)
    }
  }, [error])

  async function handleSelect(image: IUnsplashImage) {
    // make an authenticated call to image.links.download_location for usage attribution
    try {
      api.Unsplash.callImageAuthUrl(image.links.download_location)
    } catch (error) {
      // ignore attribution failure
      console.log(error)
    } finally {
      onSelect({ url: image.urls.regular })
    }
  }

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault()
        formRef.current?.click()
      }
    }

    document.addEventListener('keydown', keyDownHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [])

  // const handleScroll: UIEventHandler<HTMLElement> = (e) => {
  //   const target = e.target as HTMLElement
  //   const bottom = target.scrollHeight - target.scrollTop === target.clientHeight
  //   if (bottom) {
  //     console.log('bottom')
  //   }
  // }

  return (
    <form className="flex-flex-col" onSubmit={fetchGallery}>
      <div className="pb-6 w-3/4 md:max-w-1/2">
        <SearchInput onChange={setSearchTerm} />
      </div>
      <Masonry
        breakpointCols={3}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
        // onScroll={handleScroll}
      >
        {data?.map((image, idx) => (
          <BackgroundImageItem key={idx} image={image} onSelect={handleSelect} />
        ))}
      </Masonry>
      <button className="hidden" ref={formRef} type="submit"></button>
      {isLoading && (
        <div className="fixed bottom-8 right-8">
          <LoadingSpinner />
        </div>
      )}
    </form>
  )
}

interface IBackgroundImageItemProps {
  image: IUnsplashImage
  onSelect: (image: IUnsplashImage) => void
}
const BackgroundImageItem = ({ image, onSelect }: IBackgroundImageItemProps) => {
  function handleSelect() {
    onSelect(image)
  }

  return (
    <div key={image.id} className="relative mb-6 w-full bg-white">
      <img
        onClick={handleSelect}
        className="cursor-pointer object-fill"
        alt={image.alt_description}
        src={image.urls.regular}
        // src="https://source.unsplash.com/collection/1163637/580x480"
      />
      <span
        aria-hidden="true"
        className="absolute inset-x-0 bottom-0 h-8 bg-gradient-to-t from-gray-200 opacity-90"
      />
      <div className="absolute bottom-1 left-1 flex">
        <a
          target="_blank"
          rel="noreferrer"
          href={`${image.user.links.html}?utm_source=Passport&utm_medium=referral`}
          className="break-words text-xxs"
        >
          {image.user.username}
        </a>
        <span className="break-words text-xxs">&nbsp;via&nbsp;</span>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://unsplash.com?utm_source=Passport&utm_medium=referral"
          className="break-words text-xxs"
        >
          Unsplash
        </a>
      </div>
    </div>
  )
}
