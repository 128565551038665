import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../store'

export const selectCurrentSlide = createSelector(
  (state: RootState) => state.decks.continuities,
  (state: RootState) => state.decks.slideIndex,
  (slides, index) => {
    return {
      currentSlide: slides?.[index] ?? {},
      currentSlideIndex: index,
    }
  },
)
