import { IDeckSliceInitialState } from 'data/decks/types'

interface ITitleSlideProps {
  currentDeck: IDeckSliceInitialState
}
export const TitleSlide = ({ currentDeck }: ITitleSlideProps) => {
  return (
    <div className="relative">
      {currentDeck.recipient && (
        <div className="absolute mr-2 text-white" style={{ left: '58%', top: '42%', width: '40%' }}>
          <p className="text-center font-bold" style={{ fontSize: '28px' }}>
            EXCLUSIVELY MADE FOR
          </p>
          <p className="text-center text-2xl uppercase">{currentDeck.recipient}</p>
        </div>
      )}
      <img
        src={
          'https://gps-solutions-assets.s3.us-west-2.amazonaws.com/gps_title_slide_black.png'
          // 'https://gps-solutions-assets.s3.us-west-2.amazonaws.com/gps_title_slide_blank.jpg'
        }
        className="w-full object-contain"
      />
    </div>
  )
}
