import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { Fragment, useEffect, useState } from 'react'

import { classNames } from '../utils/helpers'

export const MenuGeneric = ({ onChange, value, options, currentOption }) => {
  const [selected, setSelected] = useState(
    currentOption || { id: 0, name: 'Selected Options', value: null },
  )

  useEffect(() => {
    if (value) {
      setSelected(value)
    }
  }, [value])

  function handleChange(value) {
    setSelected(value)
    onChange(value)
  }

  return (
    <Listbox value={selected} onChange={handleChange}>
      {({ open }) => (
        <>
          <div className="min-w-6 min-h-6 relative flex items-center">
            <Listbox.Button
              className={`relative w-full cursor-pointer text-center text-xs text-gray-800 focus:outline-none focus:ring-0`}
            >
              <span className="block truncate">{selected.name}</span>
              <span
                style={{ left: '-20px' }}
                className="pointer-events-none absolute inset-y-0 flex items-center pr-2"
              >
                <SelectorIcon className="h-4 w-4 text-gray-800" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                static
                className="scrollbar absolute right-0 top-6 z-20 w-auto min-w-32 overflow-auto rounded-md bg-white py-1 text-base shadow-full-dark ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              >
                {options.map((o, idx) => (
                  <Listbox.Option
                    key={o.id || idx}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-gray-200' : '',
                        'relative cursor-default select-none py-1 pl-3 pr-6 text-xs text-gray-800',
                      )
                    }
                    value={o}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate',
                          )}
                        >
                          {o.name}
                        </span>

                        {selected ? (
                          <span
                            className={
                              'absolute inset-y-0 right-1 flex items-center pl-1.5 text-gray-800'
                            }
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}
