import { store } from '../store'
import {
  updateMultiSelect as ums,
  updateSelectedProducts as usp,
  updateSelectedSlides as uss,
} from './selectionsSlice'
import { IMultiSelectOption, SelectedProducts, SelectedSlides } from './types'

export const updateMultiSelect = (data: IMultiSelectOption) => store.dispatch(ums(data))

export const updateSelectedProducts = (data: SelectedProducts) => store.dispatch(usp(data))

export const updateSelectedSlides = (data: SelectedSlides) => store.dispatch(uss(data))
