import { useState } from 'react'
import { useAlert } from 'react-alert'
import { useQuery } from 'react-query'
// import ReactPaginate from 'react-paginate'
import axios from 'axios'
import { useHistory } from 'react-router-dom'

import { MasterSlideCard } from 'components/MasterSlideCard'
import ResultsAndSortSection from 'components/productsView/ResutlsAndSortSection'

import { api } from 'services/api'

import { addExistingSlide } from 'data/decks'
import { setCurrentContinuitySort } from 'data/globals'
import { selectContinuitySortOptions } from 'data/globals/selectors'
import { IAdminFilter } from 'data/globals/types'
import { useAppSelector } from 'data/reduxHooks'
import { selectSelectedSlides } from 'data/selections/selectors'

import { IContinuity, UserRoles } from 'types/modelTypes'

import { ReactComponent as EmptyState } from '../../assets/svgs/empty_state.svg'

const PAGE_SIZE = 5

interface IContinuitiesCardGridProps {
  userRole?: UserRoles
  filters: IAdminFilter[]
  searchTerm: string
  filterString: string[]
  sortParams: { value: string; desc: boolean }
}
export default function ContinuitiesCardGrid({
  userRole,
  filters,
  searchTerm,
  filterString,
  sortParams,
}: IContinuitiesCardGridProps) {
  const alert = useAlert()
  const history = useHistory()

  const [page, setPage] = useState(0)

  const sortOptions = useAppSelector(selectContinuitySortOptions)
  const selectedSlides = useAppSelector(selectSelectedSlides)
  const sort = useAppSelector((state) => state.globals.currentContinuitySort)

  // const scrollRef = useRef(null)

  const fetchMyContinuities = async () => {
    try {
      const { data, meta } = await api.Directory.searchMasters({
        search: searchTerm.trim(),
        offset: page * PAGE_SIZE,
        limit: PAGE_SIZE,
        filter: filterString.length > 0 ? filterString.join('_') : '',
        // salsifyUpdatedAt does not exist for continuities but is part of the global sort options
        // so replace it if needed
        order_by: sort.value === 'salsifyUpdatedAt' ? 'updatedAt' : sort.value,
        order: sort.desc ? 'DESC' : 'ASC',
        // exclusives_only: exclusivesOnly,
        // org_markup: orgMarkup,
      })
      // setTotalPages(Math.ceil(meta.total_results / PAGE_SIZE))
      return {
        data: data,
        meta,
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        alert.error(error.message)
      }
    }
  }
  const { isLoading, data } = useQuery(
    ['continuities', searchTerm, filterString, sort, page],
    () => fetchMyContinuities(),
    {
      keepPreviousData: true,
    },
  )

  // const handlePageClick = (event) => {
  //   setPage(event.selected)
  //   scrollRef.current?.scrollIntoView({ behavior: 'smooth' })
  // }

  async function handleAddSlide(slide: IContinuity) {
    addExistingSlide(slide)
    history.replace('/builder')
  }

  return (
    <>
      <ResultsAndSortSection
        data={data}
        userRole={userRole}
        filters={filters}
        onSetCurrentSort={setCurrentContinuitySort}
        onSetPage={setPage}
        sortOptions={sortOptions}
        sort={sort}
      />
      <ul
        role="list"
        // className="grid grid-cols-1 gap-x-4 gap-y-8 px-4 pt-2 sm:grid-cols-3 sm:gap-x-4 md:grid-cols-4 md:gap-x-6 xl:grid-cols-6 xl:gap-x-8"
        className="scrollbar mt-5 grid grid-cols-1 gap-x-4 gap-y-8 overflow-y-scroll px-4 pb-6 pt-2 sm:grid-cols-2 sm:gap-x-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8 2xl:grid-cols-5"
      >
        {data?.data?.map((s, idx) => {
          return (
            <MasterSlideCard
              key={idx}
              continuity={s}
              isSelected={s.id ? !!selectedSlides?.[Number(s.id)] : false}
              onAddSlide={() => handleAddSlide(s)}
              selected={selectedSlides}
              sortParams={sortParams}
            />
          )
        })}
      </ul>
      <div className="border-gr flex h-12 w-full items-center justify-center">
        {/* <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          forcePage={page}
          pageRangeDisplayed={3}
          pageCount={totalPages}
          previousLabel="<"
          renderOnZeroPageCount={null}
          className="flex"
          breakClassName="hover:text-gray-500 hover:bg-gray-100 px-2"
          activeClassName="bg-purple-300 text-white hover:bg-purple-300 hover:bg-opacity-80 hover:text-white px-2 text-sm"
          previousClassName="px-2 font-semibold hover:bg-gray-100"
          nextClassName="px-2 font-semibold hover:bg-gray-100"
          pageClassName="hidden md:flex items-center cursor-pointer hover:bg-gray-100 px-2 text-sm"
        /> */}
      </div>
      {!isLoading && data?.data?.length === 0 && (
        <div className="mt-6 w-full">
          <p className="text-center font-semibold">
            Oops... Sorry we did not find anything that matches this search
          </p>
          <EmptyState className="mx-auto" />
        </div>
      )}
    </>
  )
}
