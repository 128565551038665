import { Directory } from '../../components/tree/Directory'

export const Continuities = () => {
  return (
    <div className="mx-auto mt-6 w-full">
      <h2 className="mb-4 font-semibold">My Continuities</h2>
      <Directory />
    </div>
  )
}
