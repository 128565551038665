import {
  ChevronLeftIcon,
  ChevronRightIcon,
  MenuIcon,
  PhotographIcon,
  PlusIcon,
  RefreshIcon,
} from '@heroicons/react/solid'
import { forwardRef, memo, useCallback, useState } from 'react'

import { DraggableAttributes } from '@dnd-kit/core'
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities'
import { removeBackground } from '@imgly/background-removal'
import { LoadingSpinner } from 'components/LoadingSpinner'
import { setAlternateImage, updateCurrentSlide } from 'data/decks'
import { useAlert } from 'react-alert'
import { api } from 'services/api'
import { ContinuityType, IContinuity, IProduct } from 'types/modelTypes'
import { captureSlide, classNames } from 'utils/helpers'

interface IProductSelectorProps {
  product: IProduct
  continuity: IContinuity
  idx: number
  onAddProduct: (idx: number) => void
  horizontal?: boolean
  size?: string
  containerStyle?: Record<string, any>
  attributes?: DraggableAttributes
  listeners?: SyntheticListenerMap
  style?: Record<string, any>
}
const ProductSelector = memo(
  forwardRef(
    (
      {
        product,
        continuity,
        idx,
        onAddProduct,
        horizontal,
        // size = '250px',
        size,
        containerStyle,
        attributes,
        listeners,
        style,
      }: IProductSelectorProps,
      ref: React.ForwardedRef<HTMLDivElement>,
    ) => {
      const alert = useAlert()
      const [alternateImageIndex, setAlternateImageIndex] = useState(0)
      const [showImageOptions, setShowImageOptions] = useState(false)
      const [isLoading, setIsLoading] = useState(false)
      const [useTransparentImage, setUseTransparentImage] = useState(true)

      const imageSource = useCallback(() => {
        const product = continuity.products?.[idx]
        const altImageIdx = continuity.altImages[product?.id]
        return altImageIdx >= 0
          ? product?.imageAssets[altImageIdx]?.replace(/https?/, 'https')
          : product?.transparentImage && useTransparentImage
          ? product?.transparentImage?.replace(/https?/, 'https')
          : product?.computedPrimaryImage?.replace(/https?/, 'https')
      }, [continuity, idx, useTransparentImage])

      function goNext() {
        if (Object.keys(product?.imageAssets).length === 1) return
        const index =
          product.imageAssets?.length - 1 > alternateImageIndex ? alternateImageIndex + 1 : 0
        setAlternateImageIndex(index)
        setAlternateImage({
          productId: product.id,
          altIdx: index,
        })
      }

      function goPrevious() {
        if (Object.keys(product?.imageAssets).length === 1) return
        const index =
          alternateImageIndex < 1 ? product.imageAssets?.length - 1 : alternateImageIndex - 1
        setAlternateImageIndex(index)
        setAlternateImage({
          productId: product.id,
          altIdx: index,
        })
      }

      function handleReplaceProduct() {
        setShowImageOptions(false)
        onAddProduct(idx)
      }

      async function handleRemoveProductBackground() {
        // if product has transparent image, we just want to toggle it on/off
        if (product.transparentImage) {
          setUseTransparentImage((prev) => !prev)
          return
        }
        setIsLoading(true)
        try {
          const imageBlob = await removeBackground(product.computedPrimaryImage, {
            output: {
              format: 'image/png',
            },
          })
          // The result is a blob encoded as PNG. It can be converted to an URL to be used as HTMLImage.src
          const formData = new FormData()
          formData.append('file', imageBlob)
          formData.append('salsifyProductId', product.productId)
          const { data } = await api.Products.uploadTransparentImage(formData)

          updateCurrentSlide({
            ...continuity,
            products: continuity.products.map((p, i) => {
              if (i === idx) {
                return {
                  ...p,
                  transparentImage: data,
                }
              }
              return p
            }),
          })
        } catch (error) {
          console.log('error removing background', error)
          alert.error('Error removing background')
        } finally {
          setIsLoading(false)
        }
      }

      return (
        <div
          ref={ref}
          key={idx}
          className={classNames(
            'relative flex h-full cursor-pointer items-center justify-center',
            `${horizontal ? 'flex-col' : ''}`,
          )}
          style={{ ...containerStyle, ...style }}
        >
          {imageSource() ? (
            <>
              <img
                onClick={(e) => {
                  e.stopPropagation()
                  setShowImageOptions(!showImageOptions)
                }}
                onLoad={() => {
                  captureSlide()
                }}
                className="h-full object-contain align-middle"
                style={{ height: size, width: size }}
                src={imageSource()}
              />
              {horizontal ? (
                <div className="min-h-14 line-clamp-2 h-14 text-ellipsis text-center">
                  {product.name}
                </div>
              ) : null}
              {showImageOptions && (
                <div className="ignore-capture absolute bottom-2 flex justify-evenly px-1">
                  <div className="flex w-full gap-3 items-center justify-evenly rounded bg-white p-1 shadow-btn">
                    <button onClick={goPrevious} className="text-gray-500 hover:text-gray-300">
                      <ChevronLeftIcon className="h-5 w-5" />
                    </button>
                    <button onClick={goNext} className="text-gray-500 hover:text-gray-300">
                      <ChevronRightIcon className="h-5 w-5" />
                    </button>
                    <button
                      onClick={handleReplaceProduct}
                      className="flex flex-col items-center text-gray-500 hover:text-gray-400"
                    >
                      <RefreshIcon className="h-5 w-5" />
                      <span className="text-3xs">Replace Product</span>
                    </button>
                    <button
                      onClick={handleRemoveProductBackground}
                      className="flex flex-col items-center text-gray-500 hover:text-gray-400"
                    >
                      <PhotographIcon className="h-5 w-5" />
                      <span className="text-3xs">Remove Background</span>
                    </button>
                    {continuity.type !== ContinuityType.single && (
                      <div
                        {...attributes}
                        {...listeners}
                        className="mx-2 flex flex-col items-center text-gray-500 hover:text-gray-400"
                      >
                        <MenuIcon className="h-5 w-5" />
                        <span className="text-3xs">Drag</span>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          ) : (
            <button
              onClick={(e) => {
                e.stopPropagation()
                onAddProduct(idx)
              }}
              style={{ height: size, width: size }}
              className="h-full w-full p-3"
            >
              <div className="flex h-full w-full flex-col items-center justify-center rounded-lg border border-gray-500">
                <PlusIcon className="h-16 w-16 text-gray-400" />
                <p>Add Product</p>
              </div>
            </button>
          )}
          {isLoading && (
            <div className="ignore-capture absolute top-0 right-0 left-0 bottom-0 flex z-30 items-center justify-center bg-white opacity-70">
              <LoadingSpinner />
            </div>
          )}
        </div>
      )
    },
  ),
)

ProductSelector.displayName = 'ProductSelector'

export { ProductSelector }
