import { store } from '../store'
import {
  addFavorites as af,
  getAll,
  removeFavorites as rf,
  updateFavorites as uf,
} from './favoritesSlice'

export const updateFavorites = (data) => store.dispatch(uf(data))

export const getAllFavorites = (user) => store.dispatch(getAll(user))

export const addFavorites = (productIds) => store.dispatch(af(productIds))

export const removeFavorites = (productIds) => store.dispatch(rf(productIds))
