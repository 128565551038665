import { useState } from 'react'
// import { Link } from 'react-router-dom'
import { FolderIcon, TrashIcon } from '@heroicons/react/solid'

export const MyListCard = ({ item, onClick, onDelete, disabled = false }) => {
  const [hoverActive, setHoverActive] = useState(false)

  function handleItemClick(e) {
    if (disabled) return
    onClick(item)
  }

  function onMouseEnter() {
    setHoverActive(true)
  }

  function onMouseLeave() {
    setHoverActive(false)
  }

  function handleDelete(e) {
    if (disabled) return
    onDelete(item)
  }

  return (
    <section className="h-full sm:max-h-64" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <li className="relative flex h-full flex-col justify-between shadow-full-light hover:shadow-full-dark">
        {!item.products || item.products.length === 0 ? (
          <FolderIcon className="mx-auto h-36 w-36 text-gray-200" />
        ) : (
          <div onClick={handleItemClick} className="grid cursor-pointer grid-cols-2 gap-0">
            <div className="aspect-h-16 aspect-w-16">
              <img
                src={item.products[0]?.computedPrimaryImage}
                alt=""
                className="self-center object-contain"
              />
            </div>
            <div className="grid grid-cols-2 grid-rows-2 gap-1">
              <div className="aspect-h-8 aspect-w-8 bg-gray-100">
                {item.products[1]?.computedPrimaryImage && (
                  <img
                    src={item.products[1]?.computedPrimaryImage}
                    alt=""
                    className="object-contain"
                  />
                )}
              </div>
              <div className="aspect-h-8 aspect-w-8 bg-gray-100">
                {item.products[2]?.computedPrimaryImage && (
                  <img
                    src={item.products[2]?.computedPrimaryImage}
                    alt=""
                    className="object-contain"
                  />
                )}
              </div>
              <div className="aspect-h-8 aspect-w-8 bg-gray-100">
                {item.products[3]?.computedPrimaryImage && (
                  <img
                    src={item.products[3]?.computedPrimaryImage}
                    alt=""
                    className="object-contain"
                  />
                )}
              </div>
              <div className="aspect-h-8 aspect-w-8 bg-gray-100">
                {item.products[4]?.computedPrimaryImage && (
                  <img
                    src={item.products[4]?.computedPrimaryImage}
                    alt=""
                    className="border-0 object-contain"
                  />
                )}
              </div>
            </div>
          </div>
        )}
        <div className="flex items-end justify-between p-5">
          <div className="flex flex-col justify-end">
            <p className="block text-sm font-medium text-gray-500">
              {item.products?.length ?? 0} Products
            </p>
            <div
              // to={`/products/${item.id}`}
              className="line-clamp-2 pr-1 text-sm font-semibold text-gray-900"
            >
              {item.name || 'Unknown'}
            </div>
          </div>
          {hoverActive && item.name !== 'My Favorites' && (
            <TrashIcon onClick={handleDelete} className="h-6 w-6 cursor-pointer text-red-500" />
          )}
        </div>
      </li>
    </section>
  )
}
