import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { thunkErrorHandler } from 'utils/helpers'
import { api } from '../../services/api'
import { CONTINUITY_SORT_OPTIONS, PRODUCT_SORT_OPTIONS } from './selectors'
import { REMOVE_PRODUCTS_FROM_LIST, REMOVE_PRODUCT_FROM_LIST } from './types'

const initialState = {
  filters: [],
  searchTerm: '',
  currentList: null,
  currentProductSort: PRODUCT_SORT_OPTIONS.user[0],
  currentContinuitySort: CONTINUITY_SORT_OPTIONS.user[0],
  currentDirectory: null,
  crumbs: [
    {
      name: 'Users',
      level: 'users',
      directoryId: null,
      userId: null,
      current: true,
    },
  ],
  priceAdjustOptions: [
    { id: 0, name: 'None', value: 1 },
    { id: 1, name: '20%', value: 0.8 },
    { id: 2, name: '25%', value: 0.75 },
    { id: 3, name: '30%', value: 0.7 },
    { id: 4, name: '35%', value: 0.65 },
    { id: 5, name: 'Custom', value: 'custom' },
  ],
}

export const removeProductFromList = createAsyncThunk(
  REMOVE_PRODUCT_FROM_LIST,
  async ({ listId, productId }: { listId: number; productId: number }, thunkAPI) => {
    try {
      const newList = await api.List.removeProduct(listId, productId)
      return newList
    } catch (error) {
      return thunkErrorHandler(error, thunkAPI)
    }
  },
)

export const removeProductsFromList = createAsyncThunk(
  REMOVE_PRODUCTS_FROM_LIST,
  async ({ listId, productIds }: { listId: number; productIds: number[] }, thunkAPI) => {
    try {
      const newList = await api.List.removeProducts(listId, productIds)
      return newList
    } catch (error) {
      return thunkErrorHandler(error, thunkAPI)
    }
  },
)

export const globalsSlice = createSlice({
  name: 'globals',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload
    },
    setCrumbs: (state, action) => {
      state.crumbs = action.payload
    },
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload
    },
    setCurrentList: (state, action) => {
      state.currentList = action.payload
    },
    setCurrentDirectory: (state, action) => {
      state.currentDirectory = action.payload
    },
    addPriceAdjustOption: (state, action) => {
      state.priceAdjustOptions = [
        {
          id: state.priceAdjustOptions.length + 1,
          name: action.payload.name,
          value: action.payload.value,
        },
        ...state.priceAdjustOptions,
      ].sort((a, b) => b.value - a.value)
    },
    resetPriceAdjustOptions: (state) => {
      state.priceAdjustOptions = initialState.priceAdjustOptions
    },
    setCurrentProductSort: (state, action) => {
      state.currentProductSort = action.payload
    },
    setCurrentContinuitySort: (state, action) => {
      state.currentContinuitySort = action.payload
    },
    clearState: (state) => {
      state = initialState
    },
  },
  extraReducers: (builder) => {
    builder.addCase(removeProductFromList.fulfilled, (state, action) => {
      return {
        ...state,
        currentListId: Number(action.payload.data),
      }
    })
  },
})

// Action creators are generated for each case reducer function
export const {
  setFilters,
  setCrumbs,
  setSearchTerm,
  setCurrentList,
  setCurrentDirectory,
  addPriceAdjustOption,
  resetPriceAdjustOptions,
  setCurrentProductSort,
  setCurrentContinuitySort,
  clearState,
} = globalsSlice.actions

export default globalsSlice.reducer
