import { Link } from 'react-router-dom'
import CategoryCard from './CategoryCard'

export default function CategorySection({ title, categories, onCateogryClick }) {
  function handleCategoryClick(cat) {
    onCateogryClick(cat.category)
  }
  return (
    <section aria-labelledby="category-heading" className="pt-12 xl:mx-auto xl:max-w-7xl">
      <div className="px-2 sm:flex sm:items-center sm:justify-between sm:px-0">
        <h2 id="category-heading" className="text-2xl tracking-tight text-gray-900">
          {title}
        </h2>
        <Link
          to="/products"
          className="hidden text-sm font-semibold text-purple-500 hover:text-purple-300 sm:block"
        >
          BROWSE ALL CATEGORIES
          <span aria-hidden="true"> &rarr;</span>
        </Link>
      </div>

      <div className="mt-2 flow-root">
        <div className="-my-2">
          <div
            className="scrollbar relative box-content overflow-x-auto py-2 xl:overflow-x-visible"
            style={{ height: '350px' }}
          >
            <div className="min-w-screen-xl absolute flex space-x-8 px-4 py-2 xl:relative xl:grid xl:grid-cols-4 xl:gap-x-8 xl:space-x-0">
              {categories?.map((c, idx) => (
                <CategoryCard
                  key={idx}
                  name={c?.category}
                  imageSrc={c?.primaryImage}
                  onClick={() => handleCategoryClick(c)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 px-4 sm:hidden">
        <Link
          to="/products"
          className="block text-sm font-semibold text-indigo-600 hover:text-indigo-500"
        >
          BROWSE ALL CATEGORIES
          <span aria-hidden="true"> &rarr;</span>
        </Link>
      </div>
    </section>
  )
}
