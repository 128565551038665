import { TrashIcon } from '@heroicons/react/solid'
import { useState } from 'react'
import { useAlert } from 'react-alert'
import { useHistory } from 'react-router-dom'

// Components
import { ButtonSquare } from 'components/buttons/ButtonSquare'
import { Checkbox } from 'components/inputs/Checkbox'

import { formatter } from 'utils/helpers'

// Data
import { updateSelectedSlides } from 'data/selections'
import { api } from 'services/api'
import { ContinuityType, IContinuity } from 'types/modelTypes'

interface IMasterSlideCardProps {
  continuity: IContinuity
  builder?: boolean
  onAddSlide: (continuity: IContinuity) => void
  onDelete?: (slideId: string) => void
  isSelected: boolean
  selected: any
  sortParams: any
}
export const MasterSlideCard = ({
  continuity,
  builder = false,
  onAddSlide,
  onDelete,
  isSelected,
  selected,
  sortParams,
}: IMasterSlideCardProps) => {
  const history = useHistory()
  const alert = useAlert()

  const [hoverActive, setHoverActive] = useState(false)
  // const [showDeleteWarningModal, setShowDeleteWarningModal] = useState(false)

  function onMouseEnter() {
    setHoverActive(true)
  }

  function onMouseLeave() {
    setHoverActive(false)
  }

  function handleSelect(value: boolean) {
    if (continuity.id === null) return
    if (value) {
      updateSelectedSlides({
        ...selected,
        [continuity.id]: continuity,
      })
    } else {
      const updated = { ...selected }
      delete updated[continuity.id]
      updateSelectedSlides(updated)
    }
  }

  function renderSelectedButton() {
    if (isSelected || hoverActive) {
      return (
        <Checkbox
          id="slide_checkbox"
          onChange={(value) => handleSelect(value)}
          checked={isSelected}
        />
      )
    }
  }

  function renderAddToDeckButton() {
    if (!builder) return
    return hoverActive ? (
      <ButtonSquare text="Add To Deck" onClick={handleAddToDeck} small />
    ) : (
      <div className="w-5" />
    )
  }

  const handleAddToDeck = () => {
    if (builder) {
      onAddSlide(continuity)
    }
  }

  function viewDetails() {
    history.push({
      pathname: `/slides/${continuity.id}`,
      state: {
        continuity,
        fromBuilder: builder,
        sortState: sortParams,
      },
    })
  }

  async function handleDelete() {
    try {
      await api.Continuity.delete(continuity.id)
      onDelete?.(String(continuity.id!))
    } catch (error) {
      console.log(error)
      alert.error('Failed to delete continuity')
    }
  }

  return (
    <section
      className="relative h-full shadow-full-light hover:shadow-full-dark"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <li className="relative flex h-full flex-col justify-between overflow-hidden">
        <button onClick={viewDetails} className="text-left">
          <div className="focus-within:none group aspect-h-10 aspect-w-10 flex w-full cursor-pointer overflow-hidden rounded-sm bg-white outline-none focus:outline-none">
            <img
              src={continuity.thumbnail || ''}
              alt={continuity.title || ''}
              className="group-hover:opacity-75 object-contain"
              style={
                continuity.type === ContinuityType.quad_horizontal
                  ? { objectFit: 'contain' }
                  : { minWidth: '177%' }
              }
            />
          </div>
          <div className="min-h-4 px-5 py-3">
            <p className="line-clamp-1 block cursor-pointer text-xs font-medium text-gray-500">
              {continuity?.master?.title || continuity?.master?.filename || ''}
            </p>
            <p className="line-clamp-2 block cursor-pointer text-base font-semibold text-gray-900">
              {continuity.title || 'Untitled'}
            </p>
          </div>
        </button>
        <div className="flex w-full flex-grow items-end justify-between px-5 pb-5">
          <p className="block text-sm font-semibold text-gray-800">
            {formatter.format(continuity.price / 100)}/week
          </p>
          <div className="flex h-5 items-center">
            {builder ? renderAddToDeckButton() : renderSelectedButton()}
            {/* {renderFavoriteButton()} */}
          </div>
        </div>
        {hoverActive && onDelete && (
          <div className="absolute top-6 right-6">
            <TrashIcon onClick={handleDelete} className="h-6 w-6 cursor-pointer text-red-500" />
          </div>
        )}
      </li>
    </section>
  )
}
