import { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
// import { useLocation } from 'react-router'
import { useParams } from 'react-router-dom'

import { ProductCard } from '../../components/ProductCard'
import { api } from '../../services/api'

export const UserFavorites = () => {
  const alert = useAlert()
  const params = useParams()
  const { uuid } = params || {}

  const [user, setUser] = useState(null)

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const { data } = await api.User.getByUUID(uuid)
        setUser(data)
      } catch (error) {
        alert.error(error.message)
      }
    }

    if (uuid) {
      fetchUser()
    }
  }, [uuid, alert])

  return user ? (
    <div className="mx-auto">
      <h3 className="mb-4 text-xl font-bold">Favorites for {user.name}</h3>
      <ul
        role="list"
        className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8"
      >
        {user.favorites.map((prod, i) => (
          <ProductCard
            key={`${prod['salsify:id']}_${i}`}
            isAdmin={true}
            product={prod}
            favorites={null}
            showFavorites={false}
          />
        ))}
      </ul>
    </div>
  ) : null
}
