import { IContinuity, IContinuityMaster, IDirectory, IUser } from '../../types/modelTypes'

export const CREATE_DECK = 'decks/createDeck'
export const UPDATE_DECK = 'decks/updateDeck'
export const FETCH_DECK = 'decks/fetchDeck'

/* **************************************************
State
************************************************** */
export interface IDeckSliceInitialState extends IContinuityMaster {
  budget: number
  userId: number | null
  filename: string
  clientId: number | null
  directoryId: number | null
  directory: IDirectory | null
  slideIndex: number
  defaultModifier: string | number
  defaultLayout: string
  defaultBackgroundImage: string
}

export interface IDefaultContinuity extends Omit<Partial<IContinuity>, 'id' | 'products'> {
  id: null
  products: { id: string }[]
}

/* **************************************************
Action Payloads
************************************************** */
export interface IFetchDeckPayload {
  id: number
  withProducts?: boolean
}

export interface ICreateDeckPayload {
  newDeck: IContinuityMaster
  filename: string
  dirId: number
  user: IUser
}

export interface IUpdateDeckPayload {
  id: number
  newDeck: IContinuityMaster
  filename: string
  dirId: number
  user: IUser
}
