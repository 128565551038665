import { API, Auth } from 'aws-amplify'
import { USER_ROLES } from '../utils/types'

export const addToGroup = async (email, groupname = 'Users') => {
  let apiName = 'AdminQueries'
  let path = '/addUserToGroup'
  let myInit = {
    body: {
      username: email,
      groupname: groupname,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  }
  return await API.post(apiName, path, myInit)
}

export const removeUserFromGroup = async (email, groupname = 'Admins') => {
  let apiName = 'AdminQueries'
  let path = '/removeUserFromGroup'
  let myInit = {
    body: {
      username: email,
      groupname: groupname,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  }
  return await API.post(apiName, path, myInit)
}

let nextToken

export const listUsersInGroup = async (groupName = USER_ROLES.Users, limit = 60) => {
  let apiName = 'AdminQueries'
  let path = '/listUsersInGroup'
  let myInit = {
    queryStringParameters: {
      groupname: groupName,
      limit: limit,
      token: nextToken,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  }
  const { NextToken, ...rest } = await API.get(apiName, path, myInit)
  nextToken = NextToken
  return rest
}

export const createUser = async (username, name, role, reset) => {
  let apiName = 'AdminQueries'
  let path = '/createUser'
  let myInit = {
    body: {
      username,
      name,
      role,
      reset,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  }
  return await API.post(apiName, path, myInit)
}

export const enableUser = async (username) => {
  let apiName = 'AdminQueries'
  let path = '/enableUser'
  let myInit = {
    body: {
      username,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  }
  return await API.post(apiName, path, myInit)
}

export const disableUser = async (username) => {
  let apiName = 'AdminQueries'
  let path = '/disableUser'
  let myInit = {
    body: {
      username,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  }
  return await API.post(apiName, path, myInit)
}

export const deleteUser = async (username) => {
  let apiName = 'AdminQueries'
  let path = '/deleteUser'
  let myInit = {
    body: {
      username,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  }
  return await API.post(apiName, path, myInit)
}

export const resetUserPassword = async (username) => {
  let apiName = 'AdminQueries'
  let path = '/resetUserPassword'
  let myInit = {
    body: {
      username,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  }
  return await API.post(apiName, path, myInit)
}

export const confirmForgotPassword = async (username, password, confirmationCode) => {
  let apiName = 'AdminQueries'
  let path = '/confirmForgotPassword'
  let myInit = {
    body: {
      username,
      password,
      confirmationCode,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  }
  return await API.post(apiName, path, myInit)
}

export const resendConfirmationEmail = async (username) => {
  let apiName = 'AdminQueries'
  let path = '/resendConfirmationEmail'
  let myInit = {
    body: {
      username,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  }
  return await API.post(apiName, path, myInit)
}

export const updateUserRole = async (username, role) => {
  let apiName = 'AdminQueries'
  let path = '/updateUser'
  let myInit = {
    body: {
      username,
      role,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  }
  return await API.post(apiName, path, myInit)
}

export const getUserByUsername = async (username) => {
  console.log('NO USERNAME"', username)
  let apiName = 'AdminQueries'
  let path = '/getUser'
  let myInit = {
    queryStringParameters: {
      username,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  }
  return await API.get(apiName, path, myInit)
}
