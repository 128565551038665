import { HomeIcon } from '@heroicons/react/solid'

export default function Breadcrumbs({ pages = [], onClick }) {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-4">
        <li className="outline-none focus:outline-none">
          <div>
            <button
              onClick={() => onClick(null)}
              className="text-gray-400 outline-none hover:text-gray-500 focus:outline-none"
            >
              <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">Users</span>
            </button>
          </div>
        </li>
        {pages.map((page) => (
          <li key={page?.name}>
            <button
              onClick={() => onClick(page)}
              className="flex items-center outline-none focus:outline-none"
            >
              <svg
                className="h-5 w-5 flex-shrink-0 text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
              <span className="sr-only">{page?.name}</span>
              <span
                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                aria-current={page?.current ? 'page' : undefined}
              >
                {page?.name}
              </span>
            </button>
          </li>
        ))}
      </ol>
    </nav>
  )
}
