interface ICheckboxProps {
  label?: string
  id: string
  labelStyle?: React.CSSProperties
  checked: boolean
  onChange: (checked: boolean) => void
  containerStyle?: string
  size?: number
}
export const Checkbox = ({
  label,
  id,
  labelStyle,
  checked,
  onChange,
  containerStyle,
  size = 4,
  ...rest
}: ICheckboxProps) => {
  return (
    <div className={`flex items-center ${containerStyle}`}>
      <input
        onChange={(e) => onChange(e.target.checked)}
        id={id}
        name="checkbox"
        type="checkbox"
        className={`h-${size} w-${size} transition-colors cursor-pointer rounded border border-gray-500 text-purple-default duration-300 hover:border-gray-800 focus:ring-0`}
        checked={checked}
        {...rest}
      />
      <label
        htmlFor={id}
        className="ml-2 block cursor-pointer text-sm text-gray-900"
        style={labelStyle}
      >
        {label}
      </label>
    </div>
  )
}
