import { Tab } from '@headlessui/react'
import { useEffect, useState } from 'react'

import { setCurrentSlideIndex } from 'data/decks'
import { selectCurrentSlide } from 'data/decks/selectors'
import { useAppSelector } from 'data/reduxHooks'

import { classNames } from 'utils/helpers'

import { ContinuityType } from 'types/modelTypes'
import { ControlsDeckTab } from './Controls--DeckTab'
import { ControlsSlideTab } from './Controls--SlideTab'

export type ClientSelectOption = {
  name: string
  value: string | number
  persist?: boolean
}

export type GenericSelectMenuOption = {
  id: number
  name: string
  value: string
}

export function BuilderControls() {
  const deck = useAppSelector((state) => state.decks)
  const { currentSlide } = useAppSelector(selectCurrentSlide)

  const [selectedTabIndex, setSelectedTabIndex] = useState(0)

  useEffect(() => {
    if (currentSlide.type === ContinuityType.title) {
      setSelectedTabIndex(0)
    } else {
      setSelectedTabIndex(1)
    }
  }, [currentSlide])

  function handleTabChange(idx: number) {
    setSelectedTabIndex(idx)
    if (idx === 0) {
      // if deck tab is selected, switch current slide to the title slide
      const titleSlideIndex = deck.continuities.findIndex(
        (cont) => cont.type === ContinuityType.title,
      )
      if (titleSlideIndex !== -1) {
        setCurrentSlideIndex(titleSlideIndex)
      }
    }
  }

  return (
    <>
      <Tab.Group selectedIndex={selectedTabIndex} onChange={handleTabChange}>
        <Tab.List className="flex w-full p-1">
          <Tab
            className={({ selected }) =>
              classNames(
                selected
                  ? 'border-purple-500 text-gray-900'
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                'flex-1 whitespace-nowrap border-b-2 px-1 py-2 text-left text-sm font-medium outline-none ring-0 focus:outline-none',
              )
            }
          >
            Deck
          </Tab>
          {currentSlide.type !== ContinuityType.title && (
            <Tab
              className={({ selected }) =>
                classNames(
                  selected
                    ? 'border-purple-500 text-gray-900'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'flex-1 whitespace-nowrap border-b-2 px-1 py-2 text-left text-sm font-medium outline-none ring-0 focus:outline-none',
                )
              }
            >
              Slide
            </Tab>
          )}
        </Tab.List>

        <Tab.Panels className="px-2" style={{ height: '85%' }}>
          {/* Deck Panel */}
          <ControlsDeckTab deck={deck} />
          {/* Slide Panel */}
          <ControlsSlideTab slide={currentSlide} />
        </Tab.Panels>
      </Tab.Group>
    </>
  )
}
