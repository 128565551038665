import { useCallback, useEffect, useRef, useState } from 'react'
// type useStateSafeParams<T> = T | (() => T)
// type dispatch<T> = React.Dispatch<React.SetStateAction<T>>
/**
 * Wrapper around react's `useState` hook.
 * Use this hook to prevent memory leak as this wont call set state on unmounted component.
 *
 * @param initialValue initial state value
 */
export const useStateSafe = (initialValue) => {
  const [val, setVal] = useState(initialValue)
  const mountedRef = useRef()
  useEffect(() => {
    mountedRef.current = true
    return () => {
      mountedRef.current = false
    }
  }, [])
  const setValue = useCallback(
    (s) => {
      if (mountedRef.current) {
        setVal(s)
      }
    },
    [setVal],
  )
  return [val, setValue]
}
