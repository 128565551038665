import { useHistory } from 'react-router-dom'

import { BackButton } from '../../components/buttons/BackButton'
import { UserTabs } from '../../components/userManagement/UserTabs'

export const UserList = () => {
  const history = useHistory()

  return (
    <>
      <div className="flex h-full px-8 sm:px-16">
        <div className="flex min-w-0 flex-1 flex-col overflow-hidden">
          <div className="relative z-0 flex flex-1 overflow-hidden">
            <main className="scrollbar relative z-0 flex-1 overflow-y-auto focus:outline-none xl:order-last">
              {/* Breadcrumb */}
              <nav className="flex items-start py-3" aria-label="Breadcrumb">
                <BackButton text="Go back" onClick={history.goBack} />
              </nav>

              <article>
                {/* Profile header */}
                <div>
                  <div>
                    <img
                      className="h-32 w-full object-cover lg:h-48"
                      src={`${process.env.REACT_APP_BUCKET_BASE_URL}/public/portal-general/banner_vegas.jpeg`}
                      alt="Banner People"
                    />
                  </div>
                </div>

                {/* Tabs */}
                <div className="mt-6 sm:mt-2 2xl:mt-5">
                  <div className="mx-auto px-4 sm:px-6 lg:px-8">
                    <h3 className="pb-5 pt-6 text-4xl font-semibold">People</h3>
                    <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                      <UserTabs />
                    </nav>
                  </div>
                </div>
              </article>
            </main>
          </div>
        </div>
      </div>
    </>
  )
}
