import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'

import { classNames } from '../utils/helpers'

export function MenuDropdown({ options, icon = () => {} }) {
  return (
    <Menu as="div" className="relative ml-3">
      {({ open }) => (
        <>
          <div>
            <Menu.Button className="flex items-center focus:outline-none focus:ring-0">
              <span className="sr-only">Open menu</span>
              {icon()}
            </Menu.Button>
          </div>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="absolute right-0 z-30 mt-2 w-40 origin-top-right rounded-md bg-white py-1 shadow-full-dark ring-0 focus:outline-none"
            >
              {options.map((item) => (
                <Menu.Item key={item.label}>
                  {({ active }) => (
                    <button
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        item.handler()
                      }}
                      className={classNames(
                        active ? 'bg-gray-100' : '',
                        item.labelColor ? item.labelColor : 'text-gray-800',
                        'block w-full px-2 py-1 text-left text-sm',
                      )}
                    >
                      {item.label}
                    </button>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  )
}
