import { useAppSelector } from 'data/reduxHooks'
import { IProduct } from 'types/modelTypes'
import { formatter } from 'utils/helpers'

interface IExtendedProduct extends Omit<IProduct, 'price'> {
  price: string
}

type RequiredKeyTranslations = Partial<{
  [key in keyof IProduct]: string
}>

const requiredKeyTranslations: RequiredKeyTranslations = {
  name: 'Title',
  description: 'Body (HTML)',
  brand: 'Vendor',
  vendor: 'Vendor Name',
  category: 'Product Category',
  subCategory: 'Type',
  tags: 'Tags',
  colors: 'Color',
  manufacturerId: 'Variant SKU',
  inventory: 'Variant Inventory QTY',
  cost: 'Cost per item',
  price: 'Variant Price',
  msrp: 'Variant Compare At Price',
  freightCost: 'Shipping Cost',
  vendorIdPV2: 'Vendor ID (PV2)',
  specSheet: 'SPEC SHEET',
  computedPrimaryImage: 'Image Src 0',
  primaryImage: 'Image Src 1',
  lifestyleImage: 'Image Src 2',
  packagingImage: 'Image Src 3',
  brandLogoImage: 'Image Src 4',
  transparentImage: 'Image Src 5',
}

export default function useCSVExport() {
  const deck = useAppSelector((state) => state.decks)

  function exportJSONToCSV(objArray: RequiredKeyTranslations[]) {
    var arr = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray
    var headerStr =
      `${Object.keys(arr[0])
        .map((value) => {
          // We want to export all image urls with the same header name
          if (value.includes('Image Src')) {
            return `"Image Src"`
          }
          return `"${value}"`
        })
        .join(',')}` + '\r\n'
    var csvContent = arr.reduce(
      (st: string, next: { [s: string]: unknown } | ArrayLike<unknown>) => {
        st +=
          `${Object.values(next)
            .map(
              (value) =>
                `"${`${value ? value : ''}`
                  .replace(/"/g, '\u201d') // escape any double quotes in string
                  .replace(/#/g, '') // problematic character
                  .replace(/\*/g, '') // problematic character
                  .replace(/[\n\r]+/g, '')}"`, // escape any new lines in string
            )
            .join(',')}` + '\r\n'
        console.log('ST', st)
        return st
      },
      headerStr,
    )

    const blob = new Blob([csvContent], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = `csv_export.csv`
    a.click()
    window.URL.revokeObjectURL(url)
  }

  function translate(p: IExtendedProduct): RequiredKeyTranslations {
    const translated: { [key: string]: IExtendedProduct[keyof IExtendedProduct] } = {}
    for (const [key, translatedKey] of Object.entries(requiredKeyTranslations)) {
      if (translatedKey && key in p) {
        translated[translatedKey] = p[key as keyof IExtendedProduct]
      }
    }
    return translated
  }

  const exportCSV = () => {
    const translatedProducts = deck.continuities
      // Calculate product prices
      .flatMap(
        (continuity) =>
          continuity.products?.map((product) => ({
            ...product,
            price: formatter.format(Number(product.cost) / (Number(continuity.priceModifier) ?? 1)),
          })) ?? [],
      )
      // Filter out title slides that do not have products
      .filter((product: IExtendedProduct) => product.hasOwnProperty('productId'))
      // Translate product keys
      .map(translate)

    exportJSONToCSV(translatedProducts)
  }

  return { exportCSV }
}
