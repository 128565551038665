import { useState } from 'react'

import ProductsView from '../ProductsView'
import { ButtonSquare } from '../buttons/ButtonSquare'
import { GenericModal } from '../modals/GenericModal'

export default function ProductAccessModal({ title, orgName, show, onClose, slot, addProducts }) {
  const [products, setProducts] = useState([])

  function handleAddComplete() {
    addProducts(products)
  }

  return (
    <GenericModal title={title} show={show} onClose={onClose} showButtons={false} fullWidth={true}>
      <div className="flex justify-between">
        <div>
          <h3 className="text-xl font-bold">
            Customize Available Products {orgName ? `for ${orgName}` : ''}
          </h3>
          <span className="text-sm">
            Select which products will be visible to members of this organization.
            <br />
            Only products included in the custom filters will be shown.
          </span>
        </div>
        <div className="mr-8">
          <ButtonSquare
            text={'Cancel'}
            className="mr-2 border-purple-500 text-sm hover:bg-gray-100"
            textColor="purple"
            outline={true}
            onClick={onClose}
          />
          <ButtonSquare text={'Save'} className="text-sm text-white" onClick={handleAddComplete} />
        </div>
      </div>
      <div style={{ height: '76vh' }} className="scrollbar relative overflow-y-scroll">
        <ProductsView
          slot={slot}
          onAddComplete={handleAddComplete}
          useCache={true}
          isModal={true}
          builder={true}
          onProductsChange={setProducts}
        />
      </div>
    </GenericModal>
  )
}
