import { Auth } from 'aws-amplify'
import { useEffect } from 'react'
import { Route } from 'react-router'

import { signOut } from '../services/auth'

// import { validateToken } from '../utils/helpers'
import CONSTANTS from '../utils/constants'

export const PrivateRoute = ({ component: Component, ...rest }) => {
  // let checkUserAuth = validateToken(localStorage.getItem(CONSTANTS.STORED_TOKEN))
  // if the locally cached user token is expired, calling currentSession() will
  // attempt to use any refresh token
  useEffect(() => {
    Auth.currentSession()
      .then((data) => {
        // console.log('isValid', data)
        window.localStorage.setItem(CONSTANTS.STORED_TOKEN, data.accessToken.jwtToken)
      })
      .catch((err) => {
        console.warn('Logged out', err)
        localStorage.clear()
        signOut()
        window.location.replace('/login')
      })
  }, [])

  return (
    <Route
      {...rest}
      render={
        (props) => (
          // !checkUserAuth ? (
          //   <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
          // ) : (
          <Component {...props} />
        )
        // )
      }
    />
  )
}
