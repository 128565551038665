import React from 'react'

import Select from 'react-select'

export default function AdvancedFilterSelect({
  options,
  filterOption = undefined,
  value,
  selected,
  name,
  className,
  onChange,
  isDisabled = false,
  isSearchable = true,
  isClearable = true,
  isLoading = false,
}) {
  return (
    <Select
      onChange={onChange}
      className={`basic-single ${className}`}
      classNamePrefix="select"
      classNames={{
        menuPortal: (state) => 'scrollbar',
      }}
      styles={{
        menuPortal: ({ left, top, ...provided }, state) => ({
          ...provided,
        }),
        control: ({ left, top, ...provided }, state) => ({
          ...provided,
          borderRadius: 0,
        }),
      }}
      selected={selected}
      value={value}
      isDisabled={isDisabled}
      isLoading={isLoading}
      isClearable={isClearable}
      isRtl={false}
      isSearchable={isSearchable}
      name={name}
      menuPosition="fixed"
      menuShouldScrollIntoView={false}
      options={options}
      filterOption={filterOption}
    />
  )
}
