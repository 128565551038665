import { Dialog, RadioGroup, Transition } from '@headlessui/react'
import { Auth } from 'aws-amplify'
import { Fragment, useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { useSelector } from 'react-redux'

import { ButtonSquare } from '../buttons/ButtonSquare'
import { TextInput } from '../inputs/TextInput'

import { useStateSafe } from '../../hooks/useStateSafe'
import constants from '../../utils/constants'
import { classNames } from '../../utils/helpers'

import { updateUser as updateUserData } from '../../data/user'
import { selectUser } from '../../data/user/selectors'
import { addToGroup, createUser, deleteUser, removeUserFromGroup } from '../../services/admin'
import { api } from '../../services/api'
import { SelectMenu } from '../inputs/SelectMenu'

const settings = [
  {
    name: 'Client',
    description: 'This user would be able to view products',
    type: constants.ROLES.USER,
  },
  {
    name: 'GPS Employee',
    description: 'This user would be able to create continuities and manage users',
    type: constants.ROLES.ADMIN,
  },
]

export const NewUserModal = ({
  open = false,
  onClose,
  onAddComplete,
  editMode = false,
  user,
  onDelete,
}) => {
  const alert = useAlert()
  const currentUser = useSelector(selectUser)

  const [selected, setSelected] = useState(settings[0])
  const [loading, setLoading] = useStateSafe(false)
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [organization, setOrganization] = useState('')
  const [orgOptions, setOrgOptions] = useState([])

  async function loadOrganizations() {
    try {
      const { data } = await api.Organizations.getAll()
      setOrgOptions(data.map((org) => ({ name: org.name, value: org.id, imageUrl: org.logoUrl })))
    } catch (error) {
      alert.error(error.message)
    }
  }

  useEffect(() => {
    if (user) {
      setSelected(settings.find((s) => s.type === user.role))
      setName(user.name)
      setEmail(user.email)
      setOrganization(user.organizationId)
    }
  }, [user])

  useEffect(() => {
    loadOrganizations()
  }, [])

  const adminCreateUser = async () => {
    if (!name || !email || !selected) {
      alert.error('Name, email, and role are required')
      return
    }
    setLoading(true)
    let cognitoUser = null
    try {
      // create user in cognito
      cognitoUser = await createUser(email, name, selected.type)
      // add to admins user group if necessary but always add to users group
      // Roles are Admins/Users in cognito, admin/user in our db
      if (selected.type === constants.ROLES.ADMIN) {
        await addToGroup(email, 'Admins')
      }
      await addToGroup(email, 'Users')
      const resp = await api.User.create({
        email,
        name,
        role: selected.type,
        organization,
        uuid: cognitoUser.data.User.Username,
        status: 'invited',
      })

      alert.success(resp.message)
      setLoading(false)
      onAddComplete()
    } catch (error) {
      // if cognito user created but rest api fails, remove from cognito
      if (cognitoUser?.data) {
        await deleteUser(cognitoUser.data.User.Username)
      }
      setLoading(false)
      alert.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const updateUser = async () => {
    try {
      setLoading(true)
      if (!user?.uuid) {
        alert.error('Cannot find username')
        setLoading(false)
        return
      }
      // updates group in cognito. may not need this if we're using our own db now
      if (user.role === 'admin' && selected.type !== 'admin') {
        // remove from admins group
        await removeUserFromGroup(user.uuid, 'Admins')
      } else if (user.role === 'user' && selected.type === 'admin') {
        // add to admins group
        await addToGroup(user.uuid, 'Admins')
      }

      // only allow update to email if you are updating your own account
      if (user.email !== email && user.uuid === currentUser.uuid) {
        const cognitoUser = await Auth.currentAuthenticatedUser()
        await Auth.updateUserAttributes(cognitoUser, {
          email,
        })
      }

      // this updates the attribute on the user
      // updateUserRole(user.uuid, selected.type)
      const { data } = await api.User.update(user.id, {
        role: selected.type,
        name,
        organization,
        email,
      })
      if (currentUser.id === data.id) {
        updateUserData(data)
      }
      setLoading(false)
      alert.success('User updated')
      onAddComplete()
    } catch (error) {
      setLoading(false)
      alert.error(error.message)
    }
  }

  function handleSubmit(e) {
    e.preventDefault()
    if (editMode) {
      updateUser()
    } else {
      adminCreateUser()
    }
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          auto-reopen="true"
          className="scrollbar fixed inset-0 z-30 overflow-y-auto"
          onClose={onClose}
        >
          <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="transition-opacity fixed inset-0 bg-gray-500 bg-opacity-75" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="transition-all inline-block transform overflow-hidden rounded-sm bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl sm:my-8 sm:w-full sm:max-w-md sm:p-6 sm:align-middle">
                <h3 className="text-xl font-bold">
                  {editMode ? `Update ${user?.name}` : 'Invite User'}
                </h3>
                <form className="mt-4 flex flex-col justify-between">
                  <TextInput
                    label="Preferred Name"
                    onChange={setName}
                    placeholder="Full Name"
                    srOnly={false}
                    value={name}
                  />

                  <TextInput
                    label="Email address"
                    onChange={setEmail}
                    placeholder="Email"
                    srOnly={false}
                    value={email}
                    containerClass="my-2"
                  />

                  <SelectMenu
                    className="mb-2"
                    label="Organization"
                    options={orgOptions}
                    onChange={(opt) => setOrganization(opt.value)}
                    value={orgOptions?.find((opt) => opt.value === organization)}
                  />

                  <RadioGroup value={selected} onChange={setSelected}>
                    <RadioGroup.Label className="text-sm font-semibold text-gray-900">
                      User Role
                    </RadioGroup.Label>

                    <div className="mt-1 -space-y-px rounded-md bg-white">
                      {settings.map((setting, settingIdx) => (
                        <RadioGroup.Option
                          key={setting.name}
                          value={setting}
                          className={({ checked }) =>
                            classNames(
                              settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                              settingIdx === settings.length - 1
                                ? 'rounded-bl-md rounded-br-md'
                                : '',
                              checked ? 'z-10' : '',
                              'relative flex cursor-pointer py-2 focus:outline-none',
                            )
                          }
                        >
                          {({ active, checked }) => (
                            <>
                              <div>
                                <span
                                  className={classNames(
                                    checked
                                      ? 'border-transparent bg-purple-500'
                                      : 'border-gray-300 bg-white',
                                    active ? 'ring-sky-500 ring-2 ring-offset-2' : '',
                                    'mt-0.5 flex h-4 w-4 cursor-pointer items-center justify-center rounded-full border',
                                  )}
                                  aria-hidden="true"
                                >
                                  <span className="h-1.5 w-1.5 rounded-full bg-white" />
                                </span>
                              </div>
                              <div className="ml-3 flex flex-col">
                                <RadioGroup.Label
                                  as="span"
                                  className={classNames(
                                    checked ? 'text-sky-900' : 'text-gray-900',
                                    'block text-sm font-semibold',
                                  )}
                                >
                                  {setting.name}
                                </RadioGroup.Label>
                                <RadioGroup.Description
                                  as="span"
                                  className={classNames(
                                    checked ? 'text-sky-700' : 'text-gray-500',
                                    'block text-sm',
                                  )}
                                >
                                  {setting.description}
                                </RadioGroup.Description>
                              </div>
                            </>
                          )}
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                  <div className="flex">
                    <ButtonSquare
                      className="mr-2 h-9 whitespace-nowrap border-purple-500 py-1 text-sm text-purple-500 hover:bg-gray-100"
                      text={'Back'}
                      disabled={loading}
                      onClick={onClose}
                      outline={true}
                      color="bg-white"
                      textColor="purple"
                    />
                    <ButtonSquare
                      type="submit"
                      className="mr-2 h-9 whitespace-nowrap border-purple-500 py-1 text-sm text-white"
                      text={editMode ? 'Update' : 'Send Invite'}
                      disabled={loading}
                      onClick={handleSubmit}
                    />
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}
