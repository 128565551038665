import { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { useHistory, useLocation } from 'react-router-dom'

import { verifyEmail } from '../../services/auth'

import { ReactComponent as GPSLogo } from '../../assets/svgs/gps_logo.svg'

export const EmailVerification = () => {
  const alert = useAlert()
  const location = useLocation()
  const history = useHistory()

  const [email, setEmail] = useState('')
  const [verificationCode, setVerificationCode] = useState('')

  useEffect(() => {
    if (location.search) {
      // get username from url params
      let username = location.search.split('=')[1]
      setEmail(username)
    }
  }, [location.search])

  async function verifyCode() {
    try {
      await verifyEmail(email, verificationCode)
      alert.success('Verification complete. Please sign in.')
      history.replace('/login')
    } catch (error) {
      alert.error(typeof error.message === 'string' ? error.message : 'Error validating code')
    }
  }

  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-50 px-4 py-12 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8">
        <div>
          <GPSLogo className="mx-auto h-12 w-auto text-purple-500" />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-purple-500">
            Verify your email
          </h2>
        </div>
        <form className="mt-8 space-y-6">
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="-space-y-px rounded-md shadow-sm">
            <div>
              <label htmlFor="verification-code" className="sr-only">
                Verification Code
              </label>
              <input
                id="verification-code"
                name="verification-code"
                type="text"
                required
                onChange={(e) => setVerificationCode(e.target.value)}
                className="relative block w-full appearance-none rounded border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-purple-500 focus:outline-none focus:ring-purple-500 sm:text-sm"
                placeholder="Verification Code"
              />
            </div>
          </div>

          <button
            type="button"
            className="group relative flex w-full justify-center rounded-md border border-transparent bg-purple-500 px-4 py-2 text-sm font-medium text-white hover:bg-purple-300 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
            onClick={verifyCode}
          >
            Verify Code
          </button>
        </form>
      </div>
    </div>
  )
}
