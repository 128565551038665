import { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'

interface IProgressiveImgProps
  extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  placeholderSrc: string
  src: string | undefined
  className?: string
  alt: string
}
export default function ProgressiveImg({
  placeholderSrc,
  src,
  className,
  ...props
}: IProgressiveImgProps) {
  const [imgSrc, setImgSrc] = useState(placeholderSrc || src)

  useEffect(() => {
    setImgSrc(placeholderSrc)
    // update the image
    if (src) {
      const img = new Image()
      img.src = src
      img.onload = () => {
        setImgSrc(src)
      }
    }
  }, [src, placeholderSrc])

  if (!imgSrc) {
    return <Skeleton height={'100%'} count={1} />
  }
  return (
    <img
      src={imgSrc}
      {...props}
      alt={props.alt || ''}
      className={`w-full object-contain ${
        imgSrc === placeholderSrc ? 'blur-sm [clip-path:inset(0)]' : ''
      } ${className}`}
    />
  )
}
