// @ts-nocheck
import { IContinuity } from 'types/modelTypes'
import { store } from '../store'

import {
  addSlide as add,
  addExistingSlide as aes,
  addMasterToSlide as amts,
  addProductToSlide as apts,
  bulkCreateSlides as bcs,
  createDeck as cd,
  clear,
  createSlideFromProduct as csfp,
  deleteSlide as del,
  duplicateSlide as ds,
  fetchDeck as fd,
  setAlternateImage as sai,
  setCurrentSlideIndex as scsi,
  startNewDeck as snd,
  updateCurrentDeck as ucd,
  updateCurrentSlide as ucs,
  updateCurrentSlideType as ucst,
  updateDeck as ud,
  updateDeckLayout as udl,
  updateDeckModifier as udm,
  updateDeckSlidesDefaultImages as udsdi,
} from './decksSlice'

export const startNewDeck = () => store.dispatch(snd())

export const addSlide = () => store.dispatch(add())

export const updateCurrentSlide = (data: Partial<IContinuity>) => store.dispatch(ucs(data))

export const setCurrentSlideIndex = (index: number) => store.dispatch(scsi(index))

export const addProductToSlide = (data) => store.dispatch(apts(data))

export const updateCurrentDeck = (data) => store.dispatch(ucd(data))

export const clearDeck = () => store.dispatch(clear())

export const createDeck = (data) => store.dispatch(cd(data)).unwrap()

export const updateDeck = (data) => store.dispatch(ud(data)).unwrap()

export const fetchDeck = (data) => store.dispatch(fd(data))

export const setAlternateImage = (data) => store.dispatch(sai(data))

export const updateCurrentSlideType = (data) => store.dispatch(ucst(data))

export const duplicateSlide = () => store.dispatch(ds())

export const deleteSlide = () => store.dispatch(del())

export const bulkCreateSlides = (data) => store.dispatch(bcs(data))

export const updateDeckModifier = (data) => store.dispatch(udm(data))

export const updateDeckLayout = (data) => store.dispatch(udl(data))

export const updateDeckSlidesDefaultImages = (data) => store.dispatch(udsdi(data))

export const addMasterToSlide = (data) => store.dispatch(amts(data))

export const addExistingSlide = (slide) => store.dispatch(aes(slide))

export const createSlideFromProduct = (product) => store.dispatch(csfp(product))
