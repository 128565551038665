import { SelectMenu } from 'components/inputs/SelectMenu'
import { TextInput } from 'components/inputs/TextInput'
import { GenericModal } from 'components/modals/GenericModal'
import { addPriceAdjustOption } from 'data/globals'
import { useAppSelector } from 'data/reduxHooks'
import { useState } from 'react'
import { useAlert } from 'react-alert'
import { GenericSelectMenuOption } from './Controls'

interface IControlsPriceModifierProps {
  label: string
  onChange: (val: number) => void
  defaultModifier: string | number
}
export const ControlsPriceModifier = ({
  label,
  defaultModifier,
  onChange,
}: IControlsPriceModifierProps) => {
  const alert = useAlert()
  const [showCustomModifier, setShowCustomModifier] = useState(false)
  const [newModifierValue, setNewModifierValue] = useState<number | null>(null)

  const priceAdjustOptions = useAppSelector((state) => state.globals.priceAdjustOptions)

  async function handleAddModifier() {
    if (
      !newModifierValue ||
      typeof Number(newModifierValue) !== 'number' ||
      newModifierValue > 100 ||
      newModifierValue < 0
    ) {
      alert.error('Please enter a valid number')
      return
    }
    const newOptValue = 1 - newModifierValue / 100
    if (priceAdjustOptions.find((opt) => opt.value === newOptValue)) {
      alert.error('This value is already in use')
      return
    }
    addPriceAdjustOption({ value: newOptValue, name: newModifierValue + '%' })
    setShowCustomModifier(false)
    onChange(newOptValue)
  }

  const handleChange = (opt: GenericSelectMenuOption) => {
    if (opt.value === 'custom') {
      setShowCustomModifier(true)
    } else {
      setShowCustomModifier(false)
      onChange(Number(opt.value))
    }
  }

  return (
    <>
      <SelectMenu
        className="mb-5"
        label={label}
        options={priceAdjustOptions as GenericSelectMenuOption[]}
        onChange={handleChange}
        value={
          priceAdjustOptions?.find((opt) => opt.value === defaultModifier) ||
          priceAdjustOptions?.[5]
        }
      />
      <GenericModal
        title="Add a custom modifier"
        show={showCustomModifier}
        onClose={() => setShowCustomModifier(false)}
        onAccept={handleAddModifier}
      >
        <form>
          <TextInput
            type="number"
            onChange={(val: string) => setNewModifierValue(Number(val))}
            placeholder="New modifier ex: 19"
          />
        </form>
      </GenericModal>
    </>
  )
}
