import { useState } from 'react'
import { useAlert } from 'react-alert'
import { Link, useHistory } from 'react-router-dom'

import { forgotPassword } from '../../services/auth'

import { ReactComponent as GPSLogo } from '../../assets/svgs/gps_logo.svg'

export const ForgotPassword = () => {
  const alert = useAlert()
  const history = useHistory()

  const [email, setEmail] = useState('')

  async function handleReset(e) {
    e?.preventDefault()
    try {
      await forgotPassword(email)
      history.replace({
        pathname: '/reset_password',
        search: `?email=${email}`,
      })
    } catch (error) {
      alert.error(error.message || 'Error resetting password')
    }
  }

  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-50 px-4 py-12 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8">
        <div>
          <GPSLogo className="mx-auto h-12 w-auto text-purple-500" />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-purple-500">
            Forgot your password
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleReset}>
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="-space-y-px rounded-md shadow-sm">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="relative block w-full appearance-none rounded border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-purple-500 focus:outline-none focus:ring-purple-500 sm:text-sm"
                placeholder="Email address"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="text-sm">
              <Link to="/login" className="font-medium text-purple-500 hover:text-purple-300">
                Go Back
              </Link>
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative flex w-full justify-center rounded-md border border-transparent bg-purple-500 px-4 py-2 text-sm font-medium text-white hover:bg-purple-300 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
            >
              Continue
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
