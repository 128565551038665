import { Auth } from '@aws-amplify/auth'
import axios, { AxiosResponse } from 'axios'

import { logOut } from '../data/user'

import CONSTANTS from '../utils/constants'

const THIRTY_MINUTES = 1000 * 60 * 30

axios.interceptors.response.use(responseHandler, httpErrorHandler)

// axios.interceptors.response.use(undefined, async (error) => {
//   if (!error.response) return // some generic error

//   const { status, data, statusText } = error.response
//   switch (status) {
//     case 401:
//       console.log('401 Error:', statusText)
//     case 403:
//       console.log('403 Error:', statusText)
//     case 404:
//       console.log('404 Error text:', statusText)
//     case 422:
//       console.log('422 Error text:', statusText)
//     case 500:
//       console.log('500 Error text:', statusText)
//     default:
//       console.log('Unkown Error text:', statusText)
//   }
//   throw error.response.data
// })

axios.interceptors.request.use(
  async (config) => {
    // when amplify storage makes requests, attaching our api token to the headers will result in 400 errors
    // so here we just ignore requests to this specific bucket. Hopefully a better way to handle this somewhere
    if (config.url?.includes(process.env.REACT_APP_BUCKET_BASE_URL!)) {
      return config
    }

    config.baseURL = CONSTANTS.BASE_API_URL
    // console.log('BASE_URL', CONSTANTS.BASE_API_URL)
    config.headers['Content-Type'] = 'application/json'
    config.headers.Accept = 'application/json'

    let token
    try {
      token = window.localStorage.getItem(CONSTANTS.STORED_TOKEN)
      const expiry = window.localStorage.getItem(CONSTANTS.TOKEN_EXPIRY)
      if ((token && Date.now() > Number(expiry) + THIRTY_MINUTES) || !expiry) {
        // we want to refresh the token after 30 minutes. Auth.currentSession() will auto refresh
        // and return new token if refresh is still valid
        const session = await Auth.currentSession()
        // console.log('SESSION', session)
        window.localStorage.setItem(CONSTANTS.TOKEN_EXPIRY, String(Date.now()))
        // @ts-ignore
        window.localStorage.setItem(CONSTANTS.STORED_TOKEN, session.idToken.jwtToken)
        // @ts-ignore
        config.headers.Authorization = `Bearer ${session.accessToken.jwtToken}`
      } else if (token) {
        // otherwise use the existing token
        config.headers.Authorization = `Bearer ${token}`
      }
      return config
    } catch (error) {
      console.error(error)
      logOut()
      window.location.replace('/login')
      return config
    }
  },
  (error) => Promise.reject(error),
)

declare module 'axios' {
  export interface AxiosRequestConfig {
    raw?: boolean
    silent?: boolean
  }
}

export class HttpError extends Error {
  constructor(message?: string) {
    super(message) // 'Error' breaks prototype chain here
    this.name = 'HttpError'
    Object.setPrototypeOf(this, new.target.prototype) // restore prototype chain
  }
}

// this interceptor is used to handle all success ajax request
// we use this to check if status code is 200 (success), if not, we throw an HttpError
// to our error handler take place.
function responseHandler(response: AxiosResponse<any>) {
  const config = response?.config
  if (config.raw) {
    return response
  }
  if (response.status == 200) {
    // const data = response?.data
    // if (!data) {
    //   throw new HttpError('API Error. No data!')
    // }
    return response
  }
  throw new HttpError('API Error! Invalid status code!')
}

function httpErrorHandler(error: any) {
  if (error === null) throw new Error('Unrecoverable error!! Error is null!')
  if (axios.isAxiosError(error)) {
    //here we have a type guard check, error inside this if will be treated as AxiosError
    const res = error?.response
    const req = error?.request
    const config = error?.config //here we have access the config used to make the api call (we can make a retry using this conf)

    if (error.code === 'ERR_NETWORK') {
      console.log('connection problems..')
      throw new Error('Connection problems')
    } else if (error.code === 'ERR_CANCELED') {
      console.log('connection canceled..')
      throw new Error('Connection canceled')
    }
    if (res) {
      //The request was made and the server responded with a status code that falls out of the range of 2xx the http status code mentioned above
      const { status, data, statusText } = res
      switch (status) {
        case 401:
          throw new Error('Unauthorized')
        case 404:
          throw new Error('The requested resource does not exist or has been deleted')
        default:
          throw new Error(error.message)
      }
    } else if (req) {
      throw new Error('The request was made but no response was received')
      //The request was made but no response was received, `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in Node.js
    }
  }
  throw new Error(error.response.data)
}

export default axios
