import { classNames } from 'utils/helpers'

interface ISquareButtonProps {
  onClick: () => void
  text: String
  className?: String
  color?: 'purple' | 'green' | 'red' | 'white'
  disabled?: boolean
  outline?: boolean
  small?: boolean
  size?: 'sm' | 'md' | 'lg'
  selected?: boolean
}
export const SquareButton = ({
  onClick,
  text,
  className = '',
  color = 'purple',
  disabled = false,
  outline = false,
  size = 'md',
  selected = false,
}: ISquareButtonProps) => {
  const _bgColor = `bg-${color}`

  const _textColor = () => {
    switch (color) {
      case 'purple':
        return 'text-white'
      case 'green':
        return 'text-white'
      case 'red':
        return 'text-white'
      case 'white':
        return 'text-black'
    }
  }

  const buttonClasses = () => {
    let defaultClasses =
      'safe whitespace-nowrap rounded text-center shadow-sm justify-center sm:py-0 md:px-2'
    let sizeClasses = ''
    let outlineClasses = ''

    switch (size) {
      case 'sm':
        sizeClasses = `px-2 py-1 text-xs`
        break
      case 'md':
        sizeClasses = `h-8 px-4 py-1 text-base`
        break
      case 'lg':
        sizeClasses = `h-9 px-4 py-2 text-base text-[12px] md:text-sm`
        break
    }

    if (outline) {
      outlineClasses = `border ${_textColor()} outline-none focus:outline-none`
    } else {
      outlineClasses = `border border-transparent ${_textColor()} ${_bgColor}-500 hover:${_bgColor}-700 transition-colors outline-none duration-200 focus:outline-none`
    }

    if (selected) {
      outlineClasses += ` border-purple-500`
    }

    return classNames(defaultClasses, sizeClasses, outlineClasses, className)
  }

  return (
    <button
      role="button"
      onClick={onClick}
      type="button"
      disabled={disabled}
      className={buttonClasses()}
    >
      {text}
    </button>
  )
}
