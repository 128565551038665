import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import api from './agent'

export type RequestMeta = {
  limit: number
  offset: number
  total_results: number
  cursor: number
}
interface ResponseBody<T> {
  message: string
  data: T
  meta?: RequestMeta
}
const responseBody = <R>(response: AxiosResponse<ResponseBody<R>>) => response?.data

export const requests = {
  get: <R>(url: string): Promise<ResponseBody<R>> =>
    api.get<ResponseBody<R>>(url).then(responseBody),
  post: <T, R>(url: string, body: T, headers: AxiosRequestConfig = {}): Promise<ResponseBody<R>> =>
    api.post<ResponseBody<R>>(url, body, headers).then(responseBody),
  put: <T, R>(url: string, body: T): Promise<ResponseBody<R>> =>
    api.put<ResponseBody<R>>(url, body).then(responseBody),
  patch: <T, R>(url: string, body: T): Promise<ResponseBody<R>> =>
    api.patch<ResponseBody<R>>(url, body).then(responseBody),
  delete: <R>(url: string): Promise<ResponseBody<R>> =>
    api.delete<ResponseBody<R>>(url).then(responseBody),
}
