import axios from 'axios'
import { useAlert } from 'react-alert'

export const useErrorAlert = () => {
  const alert = useAlert()

  const errorAlert = (error: unknown, defaultMessage?: string) => {
    if (error instanceof Error) {
      alert.error(error.message)
    } else if (axios.isAxiosError(error)) {
      alert.error(error.message)
    } else {
      alert.error(defaultMessage || 'An error occurred')
    }
  }

  return { errorAlert }
}
