import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid'
import { useEffect, useState } from 'react'
import useResizeObserver from 'use-resize-observer'

import { IContinuity } from 'types/modelTypes'
import { captureSlide, classNames } from 'utils/helpers'

interface IProductSelectorBackgroundProps {
  slide: IContinuity
  children: React.ReactNode
}
export default function ProductSelectorBackground({
  slide,
  children,
}: IProductSelectorBackgroundProps) {
  const { ref } = useResizeObserver({
    onResize: ({ height }) => {
      setHeight(height)
    },
  })

  const [height, setHeight] = useState<number>()
  const [background, setBackground] = useState<{ url: string } | null>(null)
  const [overlayVisible, setOverlayVisible] = useState(true)

  useEffect(() => {
    if (slide.backgroundImage) {
      setBackground({ url: slide.backgroundImage })
    }
  }, [slide])

  useEffect(() => {
    captureSlide()
  }, [overlayVisible])

  return (
    <div
      ref={ref}
      className={classNames('relative h-full bg-cover bg-center bg-no-repeat', 'hover:bg-gray-200')}
      style={{
        width: height + 'px',
        minWidth: height + 'px',
        backgroundImage:
          slide.layout === 'background'
            ? `url(${
                background?.url ??
                'https://images.unsplash.com/photo-1545972154-9bb223aac798?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3050&q=80&exp=8&con=-15&sat=-75'
              })`
            : '',
      }}
    >
      <div
        className="h-full w-full"
        style={{
          padding: slide.layout === 'bordered' || slide.layout === 'background' ? '5%' : 0,
        }}
      >
        {children}
      </div>
      {slide.layout === 'overlay' ? (
        <>
          <div>
            {overlayVisible && slide.overlayImage ? (
              <div className="absolute inset-0 z-10 h-full w-full">
                <img src={slide.overlayImage} className="h-full w-full object-cover" />
              </div>
            ) : null}
            <div
              onClick={() => setOverlayVisible((s) => !s)}
              className="absolute z-20 top-3 right-3 cursor-pointer flex flex-col items-center hover:text-gray-400"
              style={{
                backgroundColor: 'rgba(255, 255, 255, 0.2)', // Semi-transparent white
                borderRadius: '50%',
                padding: '8px', // Optional: Adjust padding to control the size of the background
              }}
            >
              {overlayVisible ? (
                <EyeOffIcon className="h-5 w-5 text-gray-500" />
              ) : (
                <EyeIcon className="h-5 w-5 text-gray-500" />
              )}
              <span className="text-3xs text-gray-500">Toggle Overlay</span>
            </div>
          </div>
        </>
      ) : null}
    </div>
  )
}
