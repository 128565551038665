import { Tab } from '@headlessui/react'
import throttle from 'lodash.throttle'
import { useCallback, useState } from 'react'

import { ButtonSquare } from 'components/buttons/ButtonSquare'
import { BudgetInfo } from 'components/continuityBuilder/BudgetInfo'
import { GenericSelectMenuOption } from 'components/continuityBuilder/Controls'
import { ControlsPriceModifier } from 'components/continuityBuilder/Controls--PriceModifier'
import { SlideBackgroundSelector } from 'components/continuityBuilder/SlideBackgroundSelector'
import { SlideOverlaySelector } from 'components/continuityBuilder/SlideOverlaySelector'
import { SelectMenu } from 'components/inputs/SelectMenu'
import { TextInput } from 'components/inputs/TextInput'
import { WarningModal } from 'components/modals/WarningModal'
import { deleteSlide, duplicateSlide, updateCurrentSlide, updateCurrentSlideType } from 'data/decks'
import { ContinuityType, IContinuity } from 'types/modelTypes'
import constants from 'utils/constants'
import { captureSlide } from 'utils/helpers'

interface IControlsSlideTabProps {
  slide: IContinuity
}
export const ControlsSlideTab = ({ slide }: IControlsSlideTabProps) => {
  const [showWarning, setShowWarning] = useState(false)

  function setCurrentSlideType(opt: GenericSelectMenuOption) {
    updateCurrentSlideType({ type: opt.value })
    captureSlide()
  }

  function setCurrentSlideLayout(opt: GenericSelectMenuOption) {
    updateCurrentSlide({ layout: opt.value })
    captureSlide()
  }

  const throttledCaptureSlide = useCallback(throttle(captureSlide, 2000), [])

  function handleSlideTitleChange(val: string) {
    updateCurrentSlide({ title: val })
    throttledCaptureSlide()
  }

  function handleSlideQuantityChange(val: string) {
    updateCurrentSlide({ quantity: val })
  }

  function onAcceptWarning() {
    deleteSlide()
    setShowWarning(false)
  }

  return (
    <>
      <Tab.Panel
        style={{ height: '100%' }}
        className="flex flex-col bg-white focus:outline-none focus:ring-0"
      >
        <div className="flex w-5/6 flex-grow flex-col">
          <TextInput
            label="Title of Slide"
            srOnly={false}
            onChange={handleSlideTitleChange}
            value={slide.title || ''}
            containerClass="mb-5"
          />
          <SelectMenu
            className="mb-5"
            label="Slide Type"
            options={constants.SLIDE_TYPE_OPTIONS}
            onChange={setCurrentSlideType}
            value={
              constants.SLIDE_TYPE_OPTIONS.find((opt) => opt.value === slide.type) ||
              constants.SLIDE_TYPE_OPTIONS[0]
            }
          />
          <SelectMenu
            label="Layout"
            className="mb-[10px]"
            options={constants.SLIDE_LAYOUT_OPTIONS}
            disabled={slide.type === ContinuityType.quad_horizontal}
            onChange={setCurrentSlideLayout}
            value={
              constants.SLIDE_LAYOUT_OPTIONS.find((opt) => opt.value === slide.layout) ||
              constants.SLIDE_LAYOUT_OPTIONS[0]
            }
          />
          <div className="mb-[10px]">
            {slide.layout === 'background' && <SlideBackgroundSelector />}
            {slide.layout === 'overlay' && <SlideOverlaySelector />}
          </div>
          <ControlsPriceModifier
            label="Slide Price Modifier"
            onChange={(val) => updateCurrentSlide({ priceModifier: val })}
            defaultModifier={slide.priceModifier}
          />
          {slide.type !== ContinuityType.single && (
            <BudgetInfo priceModifier={Number(slide.priceModifier)} />
          )}
          <TextInput
            label="Quantity"
            srOnly={false}
            onChange={handleSlideQuantityChange}
            value={slide.quantity}
            containerClass="mb-5"
            type="number"
          />
        </div>
        <div className="flex flex-col items-center justify-end">
          <ButtonSquare
            color="bg-gray"
            onClick={duplicateSlide}
            text="Duplicate Slide"
            className="mb-2 w-5/6"
          />
          <ButtonSquare
            onClick={() => setShowWarning(true)}
            text="Delete Slide"
            color="bg-red"
            className="w-5/6"
          />
        </div>
      </Tab.Panel>
      <WarningModal
        onAccept={onAcceptWarning}
        onClose={() => setShowWarning(false)}
        title="Warning"
        content="Are you sure you want to delete this slide?"
        show={showWarning}
      />
    </>
  )
}
