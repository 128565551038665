import { TrashIcon } from '@heroicons/react/solid'
import { Storage } from 'aws-amplify'
import { useEffect, useState } from 'react'
import Masonry from 'react-masonry-css'
import { useQuery } from 'react-query'

import { LoadingSpinner } from 'components/LoadingSpinner'
import { ImageUploadButton } from 'components/buttons/ImageUploadButton'
import { SearchInput } from 'components/inputs/SearchInput'
import { useErrorAlert } from 'hooks/useErrorAlert'
import { api } from 'services/api'
import { IGalleryItem } from 'types/modelTypes'

import './masonry.css'

interface IBackgroundImageModalPassportTabProps {
  onSelect: ({ url }: { url: string }) => void
}
export default function BackgroundImageModalPassportTab({
  onSelect,
}: IBackgroundImageModalPassportTabProps) {
  const { errorAlert } = useErrorAlert()

  const [searchTerm, setSearchTerm] = useState('')

  const fetchGallery = async () => {
    try {
      const { data } = await api.Gallery.getAll({ searchTerm })
      return data
    } catch (error) {
      errorAlert(error)
    }
  }

  const { isLoading, error, data, refetch } = useQuery(
    ['passport-images', searchTerm],
    () => fetchGallery(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  )

  useEffect(() => {
    if (error) {
      errorAlert(error)
    }
  }, [error])

  function handleDelete() {
    fetchGallery()
  }

  const handleSearch = (val: string) => {
    setSearchTerm(val)
  }

  async function handleUploadComplete({ key, name }: { key: string; name: string }) {
    try {
      const url = `${process.env.REACT_APP_BUCKET_BASE_URL}/public/${key}`
      await api.Gallery.create({ key, name, url })
      refetch()
    } catch (error) {
      errorAlert(error)
    }
  }
  return (
    <div className="flex-flex-col">
      <div className="flex pb-6 justify-between">
        <div className="w-3/4 md:max-w-1/2">
          <SearchInput onChange={handleSearch} />
        </div>
        <ImageUploadButton onUploadComplete={handleUploadComplete} />
      </div>
      <Masonry
        breakpointCols={3}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {data?.map((image, idx) => (
          <BackgroundImageItem
            key={idx}
            image={image}
            onSelect={onSelect}
            onDelete={handleDelete}
            alertError={errorAlert}
          />
        ))}
      </Masonry>
      {isLoading && (
        <div className="fixed bottom-8 right-8">
          <LoadingSpinner />
        </div>
      )}
    </div>
  )
}

interface IBackgroundImageItemProps {
  image: IGalleryItem
  onSelect: ({ url }: { url: string }) => void
  onDelete: (key: string) => void
  alertError: (error: unknown) => void
}
const BackgroundImageItem = ({
  image,
  onSelect,
  onDelete,
  alertError,
}: IBackgroundImageItemProps) => {
  const [hoverActive, setHoverActive] = useState(false)

  function handleSelect() {
    onSelect(image)
  }

  function onMouseEnter() {
    setHoverActive(true)
  }

  function onMouseLeave() {
    setHoverActive(false)
  }

  async function handleDelete(image: IGalleryItem) {
    try {
      const { data } = await api.Gallery.delete({ key: image.key })
      await Storage.remove(image.key)
      onDelete(data)
    } catch (error) {
      alertError(error)
    }
  }
  return (
    <div
      key={image.id}
      className="relative mb-6 w-full bg-white"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <img
        onClick={handleSelect}
        className="cursor-pointer object-fill"
        alt={image.key}
        src={image.url}
        // src="https://source.unsplash.com/collection/1163637/580x480"
      />
      {/* <span className="break-words">{image.name}</span> */}
      {hoverActive && (
        <TrashIcon
          onClick={() => handleDelete(image)}
          className="absolute right-2 top-2 z-10 h-5 w-5 cursor-pointer text-red-500 hover:text-red-700"
        />
      )}
    </div>
  )
}
