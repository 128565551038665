/* eslint-disable react/display-name */
import React from 'react'
import CreatableSelect from 'react-select/creatable'
import VirtualList from 'react-tiny-virtual-list'

export default function AdvancedFilterComboBox({
  disabled,
  options,
  onChange,
  onBlur,
  className,
  allowCreate = false,
  isMulti = true,
  values,
}) {
  return (
    <CreatableSelect
      onChange={onChange}
      isMulti={isMulti}
      name="filters"
      options={options}
      className={`basic-multi-select ${className} scrollbar`}
      value={values}
      classNamePrefix="select"
      isDisabled={disabled}
      onBlur={onBlur}
      menuPosition="fixed"
      captureMenuScroll={false}
      allowCreate={allowCreate}
      components={{
        MenuList: ({ children, innerProps }) => {
          if (Array.isArray(children)) {
            return (
              <VirtualList
                width="100%"
                height={300}
                itemCount={options?.length ?? 0}
                className="scrollbar"
                itemSize={50} // Also supports variable heights (array or function getter)
                renderItem={({ index, style }) => (
                  <div key={index} style={style} {...innerProps}>
                    {children[index]}
                    {/* // The style property contains the item's absolute position */}
                  </div>
                )}
              />
            )
          } else {
            return <div className="h-72 w-full px-4 pt-4 text-center text-gray-400">No Options</div>
          }
        },
      }}
    />
  )
}
