import { createBrowserHistory } from 'history'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { useAnalyticsEventTracker } from '../utils/hooks'

export const history = createBrowserHistory()

export const PageListener = ({ children }) => {
  const location = useLocation()
  const { pageTracker } = useAnalyticsEventTracker()

  useEffect(() => {
    pageTracker(location.pathname)
  }, [location])

  return children
}
