import { GenericModal } from '../modals/GenericModal'
import { BackgroundImageTabs } from './BackgroundImageTabs'

import './masonry.css'

interface IBackgroundImageModalProps {
  show: boolean
  onClose: () => void
  onSelect: ({ url }: { url: string }) => void
}
export default function BackgroundImageModal({
  show,
  onClose,
  onSelect,
}: IBackgroundImageModalProps) {
  return (
    <GenericModal
      title="Select Photo"
      show={show}
      onClose={onClose}
      showButtons={false}
      fullWidth={true}
    >
      <div className="flex-flex-col">
        <BackgroundImageTabs onSelect={onSelect} />
      </div>
    </GenericModal>
  )
}
