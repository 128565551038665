import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { TextInput } from '../inputs/TextInput'
import { GenericModal } from './GenericModal'

import { Directory } from '../tree/Directory'

export default function AddFileModal({ title, show, onClose = () => {}, onAccept = () => {} }) {
  const deck = useSelector((state) => state.decks)

  const [fileName, setFileName] = useState('')
  const [currentDir, setCurrentDir] = useState(null)

  useEffect(() => {
    setFileName(deck.filename)
  }, [deck])

  function handleAccept() {
    if (fileName) {
      onAccept(fileName, currentDir)
    }
  }

  return (
    <GenericModal title={title} show={show} onClose={onClose} onAccept={handleAccept}>
      <div>
        <div className="min-h-64 scrollbar h-64 w-full overflow-y-auto">
          <Directory onDirChange={setCurrentDir} />
        </div>
        {currentDir && currentDir.level !== 'users' && currentDir.level !== 'user' && (
          <TextInput
            srOnly={false}
            label="File Name"
            onChange={setFileName}
            placeholder="File name"
            value={fileName}
          />
        )}
      </div>
    </GenericModal>
  )
}
