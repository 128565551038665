import { DotsVerticalIcon } from '@heroicons/react/solid'
import { useState } from 'react'

import { MenuDropdown } from '../MenuDropdown'

export const OrganizationListItem = ({ org, onManageClick, onDelete }) => {
  const [isActive, setIsActive] = useState(false)

  function handleManage() {
    onManageClick(org)
  }

  function handleMouseEnter() {
    setIsActive(true)
  }

  function handleMouseLeave() {
    setIsActive(false)
  }

  const SELECTED_OPTIONS = [
    { id: 1, label: 'Manage', handler: handleManage },
    {
      id: 2,
      label: 'Remove organization',
      labelColor: 'text-red-500',
      handler: () => onDelete(org),
    },
  ]

  return (
    <li
      className={`mx-2 flex items-center justify-between px-4 py-4 ${
        isActive ? 'shadow-full-dark' : 'shadow-full-light'
      }`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        onClick={handleManage}
        className="flex min-w-0 flex-1 cursor-pointer items-center space-x-3"
      >
        {org.logoUrl ? (
          <img src={org.logoUrl} className="h-8 w-8 rounded" />
        ) : (
          <div className="flex h-8 w-8 items-center justify-center rounded bg-gray-300 text-sm text-white">
            {org?.name?.charAt(0) || '?'}
          </div>
        )}
        <div className="min-w-0 flex-1">
          <p className="truncate text-xs font-medium text-gray-900">{org.name}</p>
          <p className="truncate text-xxs font-medium text-gray-400">
            {org.members?.length} Members
          </p>
        </div>
      </div>
      <div className="flex flex-shrink-0 items-center">
        <MenuDropdown
          icon={() => (
            <DotsVerticalIcon className="h-6 w-6 rounded-full p-1 text-gray-800 hover:bg-gray-100" />
          )}
          options={SELECTED_OPTIONS}
          onChange={() => {}}
        />
      </div>
    </li>
  )
}
