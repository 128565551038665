import { useEffect, useState } from 'react'

import { Checkbox } from '../inputs/Checkbox'
import { TextInput } from '../inputs/TextInput'

export const CustomPriceRange = ({ onChange, type, checkedProp, hidePrefix = false }) => {
  const [start, setStart] = useState('')
  const [end, setEnd] = useState('')
  const [checked, setChecked] = useState(false)

  function handleChange(val) {
    send(val)
  }

  function handleStart(val) {
    setStart(val)
  }

  function handleEnd(val) {
    setEnd(val)
  }

  function send(val) {
    const filterText = `${type}+${start || 0},${end || 999999}`
    onChange(val, filterText, start, end)
  }

  useEffect(() => {
    setChecked(checkedProp)
  }, [checkedProp])

  useEffect(() => {
    const filterText = `${type}+${start || 0},${end || 999999}`
    onChange(checkedProp, filterText)
  }, [checkedProp, start, end])

  return (
    <div className="inline-flex items-center justify-between">
      <Checkbox onChange={handleChange} checked={checked} />
      <TextInput
        style={{ height: '2rem' }}
        value={start}
        onChange={handleStart}
        prefix={hidePrefix ? false : true}
      />
      <span className="mx-1">to</span>
      <TextInput
        style={{ height: '2rem' }}
        value={end}
        onChange={handleEnd}
        prefix={hidePrefix ? false : true}
      />
    </div>
  )
}
