import { Route } from 'react-router'

import Home from '../pages/Home'
import { ProductDetail } from '../pages/ProductDetail'
import { Products } from '../pages/Products'
import Profile from '../pages/Profile'
import { SlideDetail } from '../pages/SlideDetail'
import { Continuities } from '../pages/admin/Continuities'
import { UserFavorites } from '../pages/admin/UserFavorites'
import { UserList } from '../pages/admin/UserList'
import Builder from './Builder'

import { NavBar } from '../components/NavBar'
import { PrivateRoute } from '../components/PrivateRoute'
import { SwitchRouter } from '../components/SwitchRouter'

export const Dashboard = () => {
  return (
    <main className="h-screen">
      <NavBar builder={false} />
      <div className="absolute inset-0 mx-auto overflow-y-scroll" style={{ top: '67px' }}>
        <SwitchRouter>
          <Route exact path={`/home`} component={Home} />
          <Route exact path={`/products`} component={Products} />
          <Route exact path={`/products/:id`} component={ProductDetail} />
          <Route exact path={`/slides/:id`} component={SlideDetail} />
          <Route path={`/profile/:id`} component={Profile} />
          <PrivateRoute exact path="/admin/user_list" component={UserList} />
          <PrivateRoute exact path="/admin/user_favorites/:uuid" component={UserFavorites} />
          <PrivateRoute exact path="/admin/continuities" component={Continuities} />
          <PrivateRoute path={`/builder`} component={Builder} />
        </SwitchRouter>
      </div>
    </main>
  )
}
