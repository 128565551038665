import { Auth } from 'aws-amplify'

export const signIn = async (username: string, password: string) => {
  try {
    const user = await Auth.signIn(username, password)
    return user
  } catch (error) {
    throw error
  }
}

export const signUp = async (name: string, email: string, password: string, role: string) => {
  try {
    const user = await Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        name,
        'custom:role': role,
      },
    })
    return user
  } catch (error) {
    throw error
  }
}

export const signOut = async () => {
  try {
    return await Auth.signOut()
  } catch (error) {
    throw error
  }
}

export const verifyEmail = async (email: string, confirmationCode: string) => {
  try {
    return await Auth.confirmSignUp(email, confirmationCode)
  } catch (error) {
    throw error
  }
}

export const forgotPassword = async (email: string) => {
  try {
    const data = await Auth.forgotPassword(email)
    return data
  } catch (error) {
    throw error
  }
}

export const resetPassword = async (username: string, code: string, password: string) => {
  try {
    return await Auth.forgotPasswordSubmit(username.trim(), code.trim(), password.trim())
  } catch (error) {
    throw error
  }
}

export const completeNewPassword = async (user: any, password: string) => {
  try {
    return await Auth.completeNewPassword(user, password, user.challengeParam.updateUserAttributes)
  } catch (error) {
    throw error
  }
}

export const changeRole = async (user: any, attrs: any) => {
  try {
    return await Auth.updateUserAttributes(user, attrs)
  } catch (error) {
    throw error
  }
}

export const getUserRole = async () => {
  const user = await Auth.currentAuthenticatedUser()
  // console.log('ROLE', user, ' to ', user.signInUserSession.accessToken.payload['cognito:groups'])
  const roles: Record<string, boolean> = {}
  user.signInUserSession.accessToken.payload['cognito:groups']?.forEach((role: any) => {
    roles[role] = true
  })
  return roles
}
