import {
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import ReactGA from 'react-ga4'
import { useLocation } from 'react-router-dom'

const isBrowser = typeof window !== `undefined`

type UseScrollPositionEffectType = (args: {
  prevPos: { x: number; y: number }
  currPos: { x: number; y: number }
}) => void

function getScrollPosition({
  element,
  useWindow,
}: {
  element: RefObject<HTMLElement> | null
  useWindow: boolean
}) {
  if (!isBrowser) return { x: 0, y: 0 }

  const target = element?.current ? element.current : document.body
  const position = target.getBoundingClientRect()

  return useWindow
    ? { x: window.scrollX, y: window.scrollY }
    : { x: position.left, y: position.top }
}

export function useScrollPosition(
  effect: UseScrollPositionEffectType,
  deps: any[],
  element: RefObject<HTMLElement> | null,
  useWindow: boolean,
  wait: number,
) {
  const position = useRef(getScrollPosition({ element, useWindow }))

  let throttleTimeout: null | ReturnType<typeof setTimeout> = null

  const callBack = () => {
    const currPos = getScrollPosition({ element, useWindow })
    effect({ prevPos: position.current, currPos })
    position.current = currPos
    throttleTimeout = null
  }

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout === null) {
          throttleTimeout = setTimeout(callBack, wait)
        }
      } else {
        callBack()
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, deps)
}

// A custom hook that builds on useLocation to parse
// the query string for you.
export function useQueryParams() {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

export const useAnalyticsEventTracker = (category = 'Passport') => {
  const eventTracker = (action = 'test action', label = 'test label') => {
    ReactGA.event({ category, action, label })
  }
  const pageTracker = (pagePath: string) => {
    if (!pagePath) {
      return
    }
    ReactGA.send({ hitType: 'pageview', page: pagePath })
  }
  return { eventTracker, pageTracker }
}

export const useInit = (callback: () => void, ...args: any[]): [() => void] => {
  const [mounted, setMounted] = useState(false)
  const resetInit = useCallback(() => setMounted(false), [])
  const memoizedCallback = useCallback(callback, args)

  useEffect(() => {
    if (!mounted) {
      setMounted(true)
      memoizedCallback()
    }
  }, [mounted, memoizedCallback])

  return [resetInit]
}
