import { FormEvent, useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { useHistory, useLocation } from 'react-router-dom'

import { setUser } from 'data/user'
import { api } from 'services/api'
import { resetPassword, signIn } from 'services/auth'
import CONSTANTS from 'utils/constants'

import { ReactComponent as GPSLogo } from '../../assets/svgs/gps_logo.svg'

export const ResetPassword = () => {
  const alert = useAlert()
  const history = useHistory()
  const location = useLocation()

  const [email, setEmail] = useState('')
  const [code, setCode] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  useEffect(() => {
    if (location.search) {
      // get username from url params
      let username = location.search.split('=')[1]
      setEmail(username)
    }
  }, [location.search])

  async function handleReset(e: FormEvent) {
    e.preventDefault()
    if (password !== confirmPassword) {
      alert.error('Passwords do not match')
      return
    }
    try {
      await resetPassword(email, code, password)
      const user = await signIn(email, password)
      const getUser = await api.User.getByUUID(user.username)
      setUser({
        ...getUser.data,
        accessToken: user.signInUserSession.accessToken.jwtToken,
      })
      localStorage.setItem(CONSTANTS.STORED_TOKEN, user.signInUserSession.accessToken.jwtToken)
      history.replace('/home')
    } catch (error) {
      console.log('error', error)
      alert.error((error as any).message || 'Error resetting password')
    }
  }

  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-50 px-4 py-12 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8">
        <div>
          <GPSLogo className="mx-auto h-12 w-auto text-purple-500" />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-purple-500">
            Please create a new password
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleReset}>
          <div className="-space-y-px rounded-md shadow-sm">
            <div>
              <label htmlFor="code" className="sr-only">
                Verification Code
              </label>
              <input
                id="code"
                name="code"
                type="text"
                required
                className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                placeholder="Verification Code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                required
                className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                placeholder="New password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="confirm-password" className="sr-only">
                Confirm Password
              </label>
              <input
                id="confirm-password"
                name="confirm-password"
                type="password"
                required
                className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-purple-500 focus:outline-none focus:ring-purple-500 sm:text-sm"
                placeholder="Confirm new password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative flex w-full justify-center rounded-md border border-transparent bg-purple-500 px-4 py-2 text-sm font-medium text-white hover:bg-purple-300 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
            >
              Reset Password
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
