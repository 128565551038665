import React from 'react'
import { IProduct } from '../../types/modelTypes'

interface IVipCardProps {
  product?: IProduct
  onClick: (product?: IProduct) => void
}
export default function VipCard({ product, onClick }: IVipCardProps) {
  return (
    <button
      onClick={() => onClick(product)}
      className="relative flex h-56 w-56 flex-col overflow-hidden p-6 hover:opacity-75"
    >
      <span aria-hidden="true" className="absolute inset-0">
        {product ? (
          <img
            src={product?.computedPrimaryImage}
            alt=""
            className="h-full w-full object-cover object-center"
          />
        ) : (
          <div className="flex h-full w-full items-center justify-center bg-black">
            <span className="text-center text-3xl text-white">View All</span>
          </div>
        )}
      </span>
      {product && (
        <span
          aria-hidden="true"
          className="absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-500 opacity-50"
        />
      )}
    </button>
  )
}
