import { Tab } from '@headlessui/react'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'

import { ButtonSquare } from 'components/buttons/ButtonSquare'
import BackgroundImageModal from 'components/continuityBuilder/BackgroundImageModal'
import { ClientSelectOption, GenericSelectMenuOption } from 'components/continuityBuilder/Controls'
import { ControlsPriceModifier } from 'components/continuityBuilder/Controls--PriceModifier'
import ComboBox from 'components/inputs/ComboBox'
import { SelectMenu } from 'components/inputs/SelectMenu'
import { TextInput } from 'components/inputs/TextInput'
import { GenericModal } from 'components/modals/GenericModal'
import {
  updateCurrentDeck,
  updateDeckLayout,
  updateDeckModifier,
  updateDeckSlidesDefaultImages,
} from 'data/decks'
import { IDeckSliceInitialState } from 'data/decks/types'
import { api } from 'services/api'
import { IGalleryItem } from 'types/modelTypes'
import constants from 'utils/constants'
import { captureSlide, classNames } from 'utils/helpers'

interface IControlsDeckTabProps {
  deck: IDeckSliceInitialState
}
export const ControlsDeckTab = ({ deck }: IControlsDeckTabProps) => {
  const alert = useAlert()
  const [clientOptions, setClientOptions] = useState<ClientSelectOption[]>([])
  const [showAddClientModal, setShowAddClientModal] = useState(false)
  const [showBackgroundImageModal, setShowBackgroundImageModal] = useState(false)
  const [newClientName, setNewClientName] = useState('')

  useEffect(() => {
    fetchClients()
  }, [])

  const fetchClients = async (selectLatest = false) => {
    try {
      const { data } = await api.Client.getAll()
      const asSelectOptions = data.map<ClientSelectOption>((d) => ({ name: d.name, value: d.id }))
      asSelectOptions.unshift({ name: '+ Add new client', value: 'add_new', persist: true })
      setClientOptions(asSelectOptions)
      if (selectLatest) {
        updateCurrentDeck({ recipient: asSelectOptions[asSelectOptions.length - 1].name })
        captureSlide()
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        alert.error(error.message)
      }
    }
  }

  function handleDeckTitleChange(val: string) {
    updateCurrentDeck({ title: val })
  }

  function handleBudgetChange(val: string) {
    updateCurrentDeck({ budget: val })
  }

  function handleDeckLayoutChange(opt: GenericSelectMenuOption) {
    updateDeckLayout(opt.value)
  }

  function handleClientSelect(opt: ClientSelectOption) {
    if (opt.value === 'add_new') {
      setShowAddClientModal(true)
    } else {
      updateCurrentDeck({ recipient: opt.name })
      captureSlide()
    }
  }

  async function handleAddClient() {
    try {
      await api.Client.create({ name: newClientName })
      setShowAddClientModal(false)
      fetchClients(true)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        alert.error(error.message)
      }
    }
  }

  return (
    <>
      <Tab.Panel className={classNames('w-5/6 bg-white', 'h-full focus:outline-none focus:ring-0')}>
        <TextInput
          label="Title of Deck"
          srOnly={false}
          onChange={handleDeckTitleChange}
          value={deck.title || ''}
          containerClass="mb-5"
        />
        <ComboBox
          items={clientOptions}
          selected={clientOptions.find((opt) => opt.name === deck.recipient)}
          label="Choose Client"
          onChange={handleClientSelect}
          containerClass="mb-5"
        />
        <TextInput
          containerClass="mb-5"
          prefix={true}
          label="Weekly Budget ($)"
          srOnly={false}
          onChange={handleBudgetChange}
          type="number"
          value={deck.budget || ''}
          onBlur={(e) => {
            handleBudgetChange(Number(deck.budget).toFixed(2))
          }}
        />
        <ControlsPriceModifier
          label="Deck Price Modifier"
          onChange={updateDeckModifier}
          defaultModifier={deck.defaultModifier}
        />
        {/* <SelectMenu
          className="mb-5"
          label="Deck Price Modifier"
          options={priceAdjustOptions as GenericSelectMenuOption[]}
          onChange={handleDeckModifierChange}
          value={
            priceAdjustOptions?.find((opt) => opt.value === deck.defaultModifier) ||
            priceAdjustOptions?.[5]
          }
        /> */}
        <SelectMenu
          className="mb-5"
          label="Layout Modifier"
          options={constants.SLIDE_LAYOUT_OPTIONS}
          onChange={handleDeckLayoutChange}
          value={
            constants.SLIDE_LAYOUT_OPTIONS.find((opt) => opt.value === deck.defaultLayout) ||
            constants.SLIDE_LAYOUT_OPTIONS[0]
          }
        />
        {deck.defaultLayout === 'background' && (
          <div className="mx-auto w-full">
            <ButtonSquare
              small
              outline
              textColor="text-gray-700"
              text="Choose Image"
              onClick={() => setShowBackgroundImageModal(true)}
            />
          </div>
        )}
      </Tab.Panel>
      <GenericModal
        title="Add a new client"
        show={showAddClientModal}
        onClose={() => setShowAddClientModal(false)}
        onAccept={handleAddClient}
      >
        <form>
          <TextInput label="Client name" onChange={setNewClientName} placeholder="Client name" />
        </form>
      </GenericModal>
      <BackgroundImageModal
        onClose={() => setShowBackgroundImageModal(false)}
        onSelect={(img: { url: string }) => {
          updateCurrentDeck({ defaultBackgroundImage: img.url })
          updateDeckSlidesDefaultImages(img.url)
          setShowBackgroundImageModal(false)
        }}
        show={showBackgroundImageModal}
      />
    </>
  )
}
