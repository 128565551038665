import React from 'react'

export default function CategoryCard({ name, imageSrc, onClick }) {
  return (
    <button
      onClick={() => onClick(name)}
      className="min-h-80 relative flex h-80 w-56 flex-col overflow-hidden p-6 shadow-full-light hover:opacity-75 xl:w-auto"
    >
      <span aria-hidden="true" className="absolute inset-0">
        <img src={imageSrc} alt="" className="h-full w-full object-cover object-center" />
      </span>
      {/* <span
        aria-hidden="true"
        className="absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-500 opacity-50"
      /> */}
      {/* <span className="relative mt-auto text-center text-xl font-bold text-white">{name}</span> */}
    </button>
  )
}
