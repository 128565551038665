import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { Fragment, useEffect, useState } from 'react'

import { classNames } from 'utils/helpers'

interface ISelectMenuProps<T> {
  options: T[]
  label: string
  onChange: (value: T) => void
  className?: string
  disabled?: boolean
  value?: any
  defaultText?: string
}
export const SelectMenu = <T extends { name: string; value: any; id: number; imageUrl?: string }>({
  options = [],
  label,
  onChange,
  className,
  disabled,
  value,
  defaultText = '',
}: ISelectMenuProps<T>) => {
  const [selected, setSelected] = useState({ name: defaultText || 'Please select', value: null })
  useEffect(() => {
    if (value) {
      setSelected(value)
    }
  }, [value])

  function handleChange(value: any) {
    onChange(value)
  }

  return (
    <Listbox value={selected} disabled={disabled} onChange={handleChange}>
      {({ open }) => (
        <>
          {label && (
            <Listbox.Label className={`block text-sm font-semibold`}>{label}</Listbox.Label>
          )}
          <div className="relative w-full">
            <Listbox.Button
              className={`relative w-full cursor-default border border-gray-700 bg-white py-2 pl-3 pr-10 text-left shadow-sm outline-none ring-0 focus:border-purple-500 focus:outline-none focus:ring-0 sm:text-sm ${className} ${
                disabled ? 'text-gray-400' : ''
              }`}
            >
              <span className="block truncate">{selected.name}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                static
                className="scrollbar absolute top-10 z-20 mt-1 max-h-60 w-full min-w-24 overflow-auto rounded-md bg-white py-1 text-base shadow-full-light ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              >
                {options.map((o, idx) => (
                  <Listbox.Option
                    key={o.id + '' + idx}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-purple-500 text-white' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-8 pr-4',
                      )
                    }
                    value={o}
                  >
                    {({ selected, active }) => (
                      <>
                        <div
                          className={classNames(
                            selected ? 'font-semibold' : 'font-normal',
                            'flex items-center truncate',
                          )}
                        >
                          {o.imageUrl && <img src={o.imageUrl} className="mr-4 h-6 w-6 rounded" />}
                          {o.name}
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-purple-500',
                              'absolute inset-y-0 left-0 flex items-center pl-1.5',
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}
