export const REMOVE_PRODUCT_FROM_LIST = 'globals/removeProductFromList'
export const REMOVE_PRODUCTS_FROM_LIST = 'globals/removeProductsFromList'

export enum AndOrType {
  'and' = 'and',
  'or' = 'or',
  'int' = 'int',
}
export interface IAdminFilter {
  key: string
  complete: boolean
  prop: {
    label: string
    value: string
    type: string
  }
  attr: {
    name: string
    label: string
    value: string
  }[]
  operator: {
    label: string
    value: string
    type: string[]
  }
  andOr: AndOrType
}
