import { TextInput } from '../inputs/TextInput'

export default function OrganizationModalPriceTierRow({ title, onChange, value = 30 }) {
  function handleChange(val) {
    onChange(val)
  }

  return (
    <div className="mb-4 flex items-center justify-between">
      <p className="flex-1 font-semibold">{title}</p>
      <div className="w-1/3">
        <TextInput
          onChange={handleChange}
          placeholder="1 - 100"
          srOnly={false}
          value={value}
          suffix="%"
          containerClass=""
          type="number"
        />
      </div>
    </div>
  )
}
