import { selectCurrentSlide } from 'data/decks/selectors'
import { useAppSelector } from 'data/reduxHooks'

import { useComputedBudget } from 'hooks/useComputedBudget'

import constants from 'utils/constants'
import { formatter } from 'utils/helpers'

interface IBudgetInfoProps {
  containerStyle?: React.CSSProperties
  priceModifier: number
}
export const BudgetInfo = ({ containerStyle = {}, priceModifier }: IBudgetInfoProps) => {
  const master = useAppSelector((state) => state.decks)
  const { currentSlide } = useAppSelector(selectCurrentSlide)
  const { budgetRemaining } = useComputedBudget()

  const weeks =
    currentSlide.type === constants.CONTINUITY_TYPES.QUAD
      ? 4
      : currentSlide.type === constants.CONTINUITY_TYPES.QUINT
      ? 5
      : 1
  const weeklyCost =
    currentSlide.products?.reduce((prev, next) => (prev += Number(next.cost || 0)), 0) || 0
  const margin = ((1 - weeklyCost / (Number(master.budget) * weeks)) * 100).toFixed(2)

  if (!master.budget || Number(master.budget) === 0) {
    return (
      <div className="flex w-full flex-col" style={containerStyle}>
        <p className="text-xs">No budget set</p>
      </div>
    )
  }

  return (
    <div className="flex w-full flex-col" style={containerStyle}>
      <span className="text-xs">Remaining budget</span>
      <p className="font-semibold">
        {budgetRemaining} of {formatter.format(master.budget * weeks)}
      </p>
      <span className="text-xs">Margin at Budget</span>
      <span
        className={`${
          Number(margin) >= (1 - priceModifier) * 100 ? 'text-green-600' : 'text-red-500'
        }`}
      >
        {margin}%
      </span>
    </div>
  )
}
