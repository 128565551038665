import { RefreshIcon } from '@heroicons/react/outline'
import { updateCurrentSlide } from 'data/decks'
import { selectCurrentSlide } from 'data/decks/selectors'
import { useAppSelector } from 'data/reduxHooks'
import { useState } from 'react'
import { captureSlide } from 'utils/helpers'
import BackgroundImageModal from './BackgroundImageModal'

export const SlideBackgroundSelector = () => {
  const { currentSlide } = useAppSelector(selectCurrentSlide)
  const [showBackgroundGallery, setShowBackgroundGallery] = useState(false)

  function setAsBackground(img: { url: string }) {
    updateCurrentSlide({
      backgroundImage: img.url,
    })
    captureSlide()
  }

  return (
    <>
      <div className="flex items-center justify-between">
        <button
          onClick={() => setShowBackgroundGallery(true)}
          className="flex items-center hover:text-gray-400"
        >
          <RefreshIcon className="h-5 w-5" />
          <span className="text-3xs">Choose photo</span>
        </button>
        {currentSlide.backgroundImage && (
          <img className="object-cover h-[30px] w-[30px]" src={currentSlide.backgroundImage} />
        )}
      </div>
      <BackgroundImageModal
        onClose={() => setShowBackgroundGallery(false)}
        onSelect={(img) => {
          setAsBackground(img)
          setShowBackgroundGallery(false)
        }}
        show={showBackgroundGallery}
      />
    </>
  )
}
