import { Redirect, Route, Switch } from 'react-router-dom'
import { NotFound } from '../pages/NotFound'

export const SwitchRouter = ({ children }) => {
  return (
    <Switch>
      {children}
      <Redirect exact from="/" to="/home" />
      <Route exact path="/not_found" component={NotFound} />
      <Redirect to="/not_found" from="*" />
    </Switch>
  )
}
