import { useState } from 'react'
import { useAlert } from 'react-alert'
import { useHistory } from 'react-router-dom'

import { completeNewPassword, signIn } from '../../services/auth'
import CONSTANTS from '../../utils/constants'

import { ReactComponent as GPSLogo } from '../../assets/svgs/gps_logo.svg'
import { setUser } from '../../data/user'
import { api } from '../../services/api'

export const NewUserResetPassword = ({ cognitoUser, onError }) => {
  const alert = useAlert()
  const history = useHistory()

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  async function handleReset() {
    if (password !== confirmPassword) {
      alert.error('Passwords do not match')
      return
    }
    try {
      // sets status in cognito to 'CONFIRMED' with no 'FORCE_PASSWORD_RESET'
      await completeNewPassword(cognitoUser, password)
      // sign in to cognito to get accessToken
      const user = await signIn(cognitoUser.challengeParam.userAttributes.email, password)
      // fetch user from rest api
      const { data } = await api.User.getByUUID(user.username)
      // set user status to active from invited in db
      await api.User.update(data.id, {
        status: 'active',
      })
      // set user in redux state
      setUser({
        ...data,
        accessToken: user.signInUserSession.accessToken.jwtToken,
      })
      localStorage.setItem(CONSTANTS.STORED_TOKEN, user.signInUserSession.accessToken.jwtToken)
      history.replace('/home')
    } catch (error) {
      // console.error('[RESET ERROR]', error)
      if (error.code === 'NotAuthorizedException') {
        onError()
      }
      alert.error(error.message || 'Error resetting password')
    }
  }

  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-50 px-4 py-12 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8">
        <div>
          <GPSLogo className="mx-auto h-12 w-auto text-purple-500" />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-purple-500">
            Please create a new password
          </h2>
        </div>
        <form className="mt-8 space-y-6">
          <div className="-space-y-px rounded-md shadow-sm">
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                required
                className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                placeholder="New password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            {/* </div>

          <div className="rounded-md shadow-sm -space-y-px"> */}
            <div>
              <label htmlFor="confirm-password" className="sr-only">
                Confirm Password
              </label>
              <input
                id="confirm-password"
                name="confirm-password"
                type="password"
                required
                className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-purple-500 focus:outline-none focus:ring-purple-500 sm:text-sm"
                placeholder="Confirm new password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
          </div>

          <div>
            <button
              type="button"
              className="group relative flex w-full justify-center rounded-md border border-transparent bg-purple-500 px-4 py-2 text-sm font-medium text-white hover:bg-purple-300 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
              onClick={handleReset}
            >
              Update Password
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
